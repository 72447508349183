import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./User.css";
import { Breadcrumb, Alert, PageHeader, Glyphicon, ButtonGroup, Button } from "react-bootstrap";
import ListUser from "./ListUser";
import NewUser from "./NewUser";
import Joyride from 'react-joyride';

export default class User extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      showAlertSuccess: false,
      showAlertError: false,
      alertMessage: '',
      users: [],
      runJoy: false,
      steps: [
        {
          target: '.btn-primary',
          content: 'Para crear un nuevo usuario presione aquí',
          placement: 'bottom',
        },
        {
          target: '.User .users',
          content: 'En este listado se muestran los usuarios existentes',
          placement: 'bottom',
        },
        {
          target: '.User .table .filter',
          content: 'Puedes filtrar ingresando un texto aquí',
          placement: 'bottom',
        },
        {
          target: '.User .table a',
          content: 'Puedes ver el detalle de un usuario haciendo click en el enlace',
          placement: 'bottom',
        }
      ]
    };
  }

  handleAlertSuccess = (message) => {
    var newState = { alertMessage: message.message, showAlertSuccess: true, showAlertError: false };
    if (message.refresh) {
      this.setState({ ...newState, isLoading: true,  });
    } else {
      this.setState(newState);
    }
  }

  handleDismissSuccess = () => {
	  this.setState({ showAlertSuccess: false });
  }

  handleAlertError = (message) => {
    this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
  }

  handleDismissError = () => {
	  this.setState({ showAlertError: false });
  }

  handleUpdate = () => {
    this.setState({ isLoading: true });
  }

  handleEndUpdate = () => {
    this.setState({ isLoading: false });
  }

  handleHelp = () => {
    this.setState({ runJoy: true });
  }

  render() {
    const { steps, runJoy } = this.state;
    return (
      <div className="User">
	  	<Breadcrumb>
	  	  <Breadcrumb.Item componentClass={Link} href="/" to="/">Tablero</Breadcrumb.Item>
	      <Breadcrumb.Item active>Usuarios</Breadcrumb.Item>
        <Glyphicon className="help" glyph="info-sign" onClick={this.handleHelp} />
      </Breadcrumb>
    	<PageHeader>
	      Usuarios <small>Configuración</small>
	    </PageHeader>
        <ButtonGroup>
          <NewUser {...this.props} onAlertSuccess={this.handleAlertSuccess} onAlertError={this.handleAlertError}/>
          <Button onClick={this.handleUpdate} className="btnRefresh">
            <Glyphicon glyph="refresh" />
          </Button>
        </ButtonGroup>
        {
        	this.state.showAlertSuccess &&
        	<Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        {
        	this.state.showAlertError &&
        	<Alert bsStyle="danger" onDismiss={this.handleDismissError}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        <ListUser isLoading={this.state.isLoading} onEndUpdate={this.handleEndUpdate} users={this.state.users} />
        <Joyride
	        steps={steps}
	        run={runJoy}
        	continuous={true}
        	locale={{ back: 'Atrás', close: 'Cerrar', last: 'Finalizar', next: 'Siguiente', skip: 'Saltar' }}
	    />
      </div>
    );
  }

}

import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Navbar, Nav, NavItem, NavDropdown, MenuItem, Alert, Image } from "react-bootstrap";
import Routes from "./Routes";
import ReactGA from 'react-ga'
import { authUser, signOutUser, getUserAttributes } from "./libs/awsLib";
//import { Offline } from "react-detect-offline";
import config from './config';
import moment from 'moment';
import { invokeApig } from './libs/awsLib';

import "./App.css";
import "./menu.css"

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      showAlertError: false,
      alertMessage: '',
      profile: null,
      fullname: '',
      expanded: false,
      timezone: (moment().utcOffset()/60)
    };
  }

  async componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    try {
      if (await authUser()) {
        this.userHasAuthenticated(true);
        this.readOwner();
      }
    }
    catch(e) {
      console.log(e);
      this.handleAlertError('Usuario no autenticado');
    }
    this.setState({ isAuthenticating: false });
  }

  readOwner = async () => {
    this.owner().then((response) => {
      this.setState({timezone: response.owner.time_zone});
    }, (error) => {
      console.log(error)
    });
  }

  owner() {
    return invokeApig({
        path: "/owner"
    });
  }

  async componentDidUpdate() {
    const user = await getUserAttributes();
    if (user.profile !== this.state.profile) {
      this.setState({ profile: user.profile });
    }
  }

  userHasAuthenticated = (authenticated, fullname) => {
    this.setState({ isAuthenticated: authenticated, fullname: fullname });
  }

  handleLogout = event => {
    event.preventDefault();
    signOutUser();

    this.userHasAuthenticated(false);

    this.props.history.push("/login");
  }

  onToggle = (event) => {
  	this.setState({ expanded: !this.state.expanded });
  }

  onSelect = (event) => {
    this.setState({ expanded: false });
  }

  onSelectMenu = (event) => {
	  this.setState({ expanded: false, openFleetMenu: false });
  }

  handleAlertError = (message) => {
    this.setState({ alertMessage: message, showAlertError: true });
  }

  handleDismissError = () => {
	  this.setState({ showAlertError: false });
  }

  renderPrivate(childProps) {
    return (
      <div className="row">
          <Navbar onToggle={this.onToggle} onSelect={this.onSelect} expanded={this.state.expanded}>
	        <Navbar.Header>
	          <Navbar.Header>
	            <Navbar.Brand>
                <Link to="/">
                  <Image src="/logo.png" responsive />
                </Link>
              </Navbar.Brand>
	            <Navbar.Toggle />
		        </Navbar.Header>
	        </Navbar.Header>
	        <Navbar.Collapse>
	          <Nav>
              <NavItem componentClass={Link} eventKey={0} href="/" to="/">Tablero</NavItem>
              <NavItem componentClass={Link} eventKey={1} href="/alerts" to="/alerts">Alertas</NavItem>
              {
                this.state.profile === 'Supervisor' &&
                <NavItem componentClass={Link} eventKey={2} href="/analysis" to="/analysis">Análisis</NavItem>
              }
              {
                this.state.profile === 'Supervisor' &&
                <NavItem componentClass={Link} eventKey={5} href="/report" to="/report">Reportes</NavItem>
              }
	          </Nav>
	          <Nav pullRight>
	          	<NavItem componentClass={Link} eventKey={3} href="/support" to="/support">Soporte</NavItem>
	          	<NavDropdown eventKey={4} title="Mi cuenta" id="nav-dropdown-profile">
	              <MenuItem eventKey={4.1} componentClass={Link} href="/profile" to="/profile">Mi perfil</MenuItem>
                {
                  this.state.profile === 'Supervisor' &&
                  <MenuItem eventKey={4.2} componentClass={Link} href="/user" to="/user">Usuarios</MenuItem>
                }
                <MenuItem eventKey={4.3} href="#" onClick={this.handleLogout} className="logout">Cerrar sesión</MenuItem>
	            </NavDropdown>
          	</Nav>
	        </Navbar.Collapse>
	      </Navbar>
        {
        	this.state.showAlertError &&
        	<Alert bsStyle="danger" onDismiss={this.handleDismissError}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
	      <Routes childProps={childProps} />
	    </div>
    );
  }

  renderPublic(childProps) {
    const ButtonAnchor = ({children, anchorClassName, ...rest }) => {
      return (
        <Link className={anchorClassName} {...rest}>{children}</Link>
      );
    };
    return (
      <div className="row">
        <Navbar collapseOnSelect fixedTop>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">
                Sentinel App
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            {/*
            <Nav>
              <NavItem eventKey={1} componentClass={Link} href="/info-sentinel" to="/info-sentinel">Telemetr&iacute;a</NavItem>
            </Nav>
            */}
            <Nav pullRight>
              { /* <NavItem eventKey={1} componentClass={ButtonAnchor} href="/contact" to="/contact" anchorClassName="btn btn-success btn-lg">Contáctanos!</NavItem> */ }
              <NavItem eventKey={2} componentClass={ButtonAnchor} href="/login" to="/login" anchorClassName="btn btn-primary btn-lg">Iniciar sesión</NavItem>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        {
          this.state.showAlertError &&
          <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
            <strong>{this.state.alertMessage}</strong>
          </Alert>
        }
        <Routes childProps={childProps} />
  		</div>
    );
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      timezone:this.state.timezone
    };

    return (
      !this.state.isAuthenticating &&
      <div className="App container">
        {/*
        <Offline>
          <Alert bsStyle="danger" className="internetAccess">
            <strong>Sin conexión a internet!</strong>
          </Alert>
        </Offline>
        */}
  	    {
  	      this.state.isAuthenticated ? this.renderPrivate(childProps) : this.renderPublic(childProps)
  	    }
        {/*
        <footer className="navbar-default">
          <div className="nav-footer">
            <a href={config.terms} target="_blank">Términos y condiciones</a>
            <a href={config.policy} target="_blank">Política de privacidad</a>
            <center className="copyright">
              © App desarrollada por <Navbar.Link href="http://www.neokode.cl" target="_blank">Neokode</Navbar.Link>
            </center>
          </div>
        </footer>
        */}
      </div>
    );
  }
}

export default withRouter(App);

import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import Home from "./containers/Home";
import Login from "./containers/Login";

import Contact from "./containers/pub/Contact";

import DashboardAnimation from "./containers/telemetry/DashboardAnimation";
import Analysis from "./containers/analysis/Analysis";
import Report from "./containers/report/Report";

import User from "./containers/user/User";
import UserDetail from "./containers/user/UserDetail";
import Profile from "./containers/user/Profile";

import Support from "./containers/support/Support";
import SupportDetail from "./containers/support/SupportDetail";
import NotFound from "./containers/NotFound";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />

    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/contact" exact component={Contact} props={childProps} />

    <AuthenticatedRoute path="/alerts" exact component={DashboardAnimation} props={childProps} />
    <AuthenticatedRoute path="/analysis" exact component={Analysis} props={childProps} />
    <AuthenticatedRoute path="/report" exact component={Report} props={childProps} />

    <AuthenticatedRoute path="/user" exact component={User} props={childProps} />
    <AuthenticatedRoute path="/user/detail" exact component={UserDetail} props={childProps} />
    <AuthenticatedRoute path="/profile" exact component={Profile} props={childProps}/>
    <AuthenticatedRoute path="/support" exact component={Support} props={childProps} />
    <AuthenticatedRoute path="/support/detail" exact component={SupportDetail} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;

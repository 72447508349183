import React, { Component } from "react";
import { Panel } from "react-bootstrap";
import './DashboardLegend.css';

export default class DashboardLegend extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Panel className="dashboard-legend">
        <Panel.Heading>
          Leyenda
        </Panel.Heading>
        <Panel.Body>
          <div className='legend'>
            <ul className='legend-labels'>
              <li data-toggle="tooltip" title="T° entre 0 y 50"><span className="green"></span><span className="legend-label">Normal</span></li>
              <li data-toggle="tooltip" title="T° entre -9 y -1 o entre 51 y 59"><span className="yellow"></span><span className="legend-label">Advertencia</span></li>
              <li data-toggle="tooltip" title="T° menor a -10 o mayor a 60"><span className="red"></span><span className="legend-label">Peligro</span></li>
              <li><span className="grey"></span><span className="legend-label">Sin datos</span></li>
              { /* <li><span className="black"></span><span className="legend-label">Error</span></li> */}
            </ul>
          </div>
        </Panel.Body>
      </Panel>
    );
  }
}

import React, { Component } from "react";
import { invokeApig } from "../../libs/awsLib";
import { Link } from "react-router-dom";
import {
    Col, Button, Modal, Glyphicon,
    Breadcrumb, PageHeader, Form, FormGroup, ControlLabel,
    FormControl, Alert
   } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import NewUser from "./NewUser";
import Joyride from 'react-joyride';
import moment from 'moment';

export default class UserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.location.state,
      isDeleting: false,
      isReseting: false,
      show: false,
      showReset: false,
      showAlertSuccess: false,
      showAlertError: false,
      alertMessage: '',
      runJoy: false,
      steps: [
    	  {
          target: '.form-horizontal',
          content: 'En esta sección se muestran los datos actuales del usuario',
          placement: 'top'
        },
        {
          target: '.form-horizontal button:nth-of-type(1)',
          content: 'Puedes resetear la clave del usuario',
          placement: 'top'
        },
        {
          target: '.form-horizontal .userBtn button',
          content: 'Puedes modificar los datos del usuario',
          placement: 'top'
        },
        {
          target: '.form-horizontal button:nth-of-type(2)',
          content: 'Puedes eliminar el usuario',
          placement: 'top'
        }
      ]
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  }

  showConfirmDelete = () => {
  	this.setState({ show: true });
  }

  handleCloseReset = () => {
    this.setState({ showReset: false });
  }
  showConfirmReset = () => {
  	this.setState({ showReset: true });
  }

  saveUser(user) {
    return invokeApig({
      path: `/user/${this.props.location.state.username}`,
      method: "PUT",
      body: user
    });
  }

  //Método que Elimina los datos del propietario seleccionado por id
  deleteUser() {
    return invokeApig({
      path: `/user/${this.props.location.state.username}`,
      method: "DELETE"
    });
  }

  handleDelete = async event => {
    event.preventDefault();
    this.setState({ isDeleting: true });
    try {
      const response = await this.deleteUser();
      this.setState({ isDeleting: false, show: false });
      if (response.errorMessage) {
        this.handleAlertError({ message: "Error al eliminar usuario!" });
      } else {
        this.handleAlertSuccess({ message: "Usuario eliminado correctamente!" });
        this.props.history.push("/user");
      }
    } catch (e) {
      this.setState({ isDeleting: false, show: false, showAlertError: true, alertMessage: "Error al eliminar usuario!" });
    }
  }

  resetPassword() {
    return invokeApig({
      path: `/user/reset`,
      method: "POST",
      body: {username: this.props.location.state.username}
    });
  }
  handleReset = async event => {
    event.preventDefault();
    this.setState({ isReseting: true });
    try {
      const response = await this.resetPassword();
      this.setState({ isReseting: false, showReset: false });
      if (response.errorMessage) {
        this.handleAlertError({ message: "Error al restablecer usuario!" });
      } else {
        this.handleAlertSuccess({ message: "Clave reseteada correctamente! Se ha enviado un correo al usuario con los detalles" });
      }
    } catch (e) {
      this.setState({ isReseting: false, showReset: false });
      this.handleAlertError({ message: "Error al resetear clave del usuario!" });
    }
  }

  handleAlertSuccess = (message, user) => {
    var newState = { ...user, alertMessage: message.message, showAlertSuccess: true, showAlertError: false };
    this.setState(newState);
  }

  handleDismissSuccess = () => {
	  this.setState({ showAlertSuccess: false });
  }

  handleAlertError = (message) => {
    this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
  }

  handleDismissError = () => {
	  this.setState({ showAlertError: false });
  }

  handleHelp = () => {
    this.setState({ runJoy: true});
  }

  dateFormatter = (dateIn) => {
    const date = moment(dateIn);
    return date.format("YYYY-MM-DD HH:mm (UTC)");
  }

  render(){
    const { steps, runJoy } = this.state;
    const { email, name, lastName, phoneNumber, profile, status, createDate, lastModifiedDate } = this.state ;
    return(
      <div className="User">
        <Breadcrumb>
  	  	  <Breadcrumb.Item componentClass={Link} href="/" to="/">Tablero</Breadcrumb.Item>
          <Breadcrumb.Item componentClass={Link} href="/user" to="/user">Usuarios</Breadcrumb.Item>
  	      <Breadcrumb.Item active>Detalle</Breadcrumb.Item>
          <Glyphicon className="help" glyph="info-sign" onClick={this.handleHelp} />
        </Breadcrumb>
      	<PageHeader>
          Usuario <small>Datos personales</small>
        </PageHeader>
        {
        	this.state.showAlertSuccess &&
        	<Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        {
        	this.state.showAlertError &&
        	<Alert bsStyle="danger" onDismiss={this.handleDismissError}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        <Form horizontal onSubmit={this.handleSubmit}>
  	        <FormGroup controlId="email">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Email{' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{email}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
  		    <FormGroup controlId="name">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Nombre{' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{name}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
  		    <FormGroup controlId="lastName">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Apellido{' '}
  		      </Col>
  		      <Col sm={6}>
  		      	<FormControl.Static>{lastName}</FormControl.Static>
  		  	  </Col>
  		    </FormGroup>
  		    <FormGroup controlId="phoneNumber">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Teléfono{' '}
  		      </Col>
  		      <Col sm={6}>
  		      	<FormControl.Static>{phoneNumber}</FormControl.Static>
  		  	  </Col>
  		    </FormGroup>
  		    <FormGroup controlId="profile">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Perfil{' '}
  		      </Col>
  		      <Col sm={6}>
  		      	<FormControl.Static>{profile}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          <FormGroup controlId="status">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Estado{' '}
  		      </Col>
  		      <Col sm={6}>
  		      	<FormControl.Static>{status}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          <FormGroup controlId="createDate">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Creado el{' '}
  		      </Col>
  		      <Col sm={6}>
  		      	<FormControl.Static>{this.dateFormatter(createDate)}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          <FormGroup controlId="lastModifiedDate">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Última modificación{' '}
  		      </Col>
  		      <Col sm={6}>
  		      	<FormControl.Static>{this.dateFormatter(lastModifiedDate)}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
  		    <FormGroup>
  		      <center className="detail-buttons">
              <Button bsStyle="primary" onClick={this.showConfirmReset} className="btnAction">Resetear clave</Button>
              <NewUser {...this.props} username={this.props.location.state.username} onAlertSuccess={this.handleAlertSuccess} onAlertError={this.handleAlertError}/>
              <Button bsStyle="primary" onClick={this.showConfirmDelete} className="btnAction">Eliminar</Button>
  		      </center>
  		    </FormGroup>
  	    </Form>
        <Modal show={this.state.showReset} onHide={this.handleCloseReset}>
          <Modal.Header closeButton>
            <Modal.Title>Restablecer clave</Modal.Title>
          </Modal.Header>
          <Modal.Body>¿Está seguro que quiere restablecer la clave del usuario?</Modal.Body>
          <Modal.Footer>
            <LoaderButton bsStyle="primary" isLoading={this.state.isReseting} onClick={this.handleReset} text="Resetear" loadingText="Reseteando…"/>
            <Button onClick={this.handleCloseReset}>Cancelar</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Eliminar usuario</Modal.Title>
          </Modal.Header>
          <Modal.Body>¿Está seguro que quiere eliminar el usuario?</Modal.Body>
          <Modal.Footer>
            <LoaderButton bsStyle="primary" isLoading={this.state.isDeleting} onClick={this.handleDelete} text="Eliminar" loadingText="Eliminando…"/>
            <Button onClick={this.handleClose}>Cancelar</Button>
          </Modal.Footer>
        </Modal>
        <Joyride
          steps={steps}
          run={runJoy}
          continuous={true}
          scrollToFirstStep={true}
          callback={this.callback}
          locale={{ back: 'Atrás', close: 'Cerrar', last: 'Finalizar', next: 'Siguiente', skip: 'Saltar' }} />
      </div>
    );
  }
}

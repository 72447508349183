import React, { Component } from "react";
import { invokeApig } from '../../libs/awsLib';
import io from 'socket.io-client';
import { Map, Marker, Polyline, GoogleApiWrapper } from 'google-maps-react';
import { Panel } from "react-bootstrap";
import { getUserAttributes } from "../../libs/awsLib";
import config from "../../config";

export class Dashboard extends Component {
  owner;
  sockets=[];
  timers=[];
  height;
  width;
  colors = {
    'green': '#58f17b',
    'yellow': '#fff900',
    'red': '#fb2626',
    'grey': 'blue',//'#ddd',
    'black': '#000'
  };
  constructor(props){
    super(props);
    this.width = 640;
    this.height = 425;
    this.imageLoad = false;
    this.state = {
      isLoading: true,
      value: [],
      stations: [],
      markers: []
    };
  }
  componentDidMount() {
    this.updateStations();
  }
  componentWillUnmount() {
    this.sockets.map((list_sockets, index) => {
      return list_sockets.map((socket, index_socket) => {
        socket.disconnect();
        console.log('socket disconnect');
        return null;
      });
    });
  }

  updateStations = async () => {
    const user = await getUserAttributes();
    this.setState({ isLoading: true });
    this.stations().then((response) => {
      this.setState({ stations: response.stations, isLoading: false, markers: response.stations });
      response.stations.map((station, index) => {
        let i = 0;
        let sensors = [];
        for (var [key, sensor] of Object.entries(station.sensors)) {
          sensors.push(this.socketStart(station, key, user.preferred_username, index, i++));
        }
        this.sockets.push(sensors);
        return null;
      });
    }, (error) => {
      this.setState({ errorMessage: "Error al actualizar las estaciones. Favor intenta más tarde.", isLoading: false});
    });
  }
  stations() {
      return invokeApig({
          path: "/station/find",
          method: "POST",
          body: {}
      });
  }
  sleep(index, index_sensor, seconds) {
    //console.log('sleep: ' + position);
    if (!this.timers[index]) {
      this.timers[index] = [];
    }
    this.timers[index][index_sensor] = setTimeout(() => this.showNodata(seconds, index, index_sensor), (seconds + 5) * 1000);
  }
  showNodata(seconds, index, index_sensor) {
    //console.log('NoData: ' + position);
    const {stations} = this.state;
    for (var [, sensor] of Object.entries(stations[index].sensors)) {
      sensor.value=undefined;
      sensor.state=undefined;
    }
    this.setState({ stations });
    this.sleep(index, index_sensor, seconds);
  }
  socketStart = (station, sensor, owner, index, index_sensor) => {
    var socket = io(config.websocket.URL, {secure: true, transports: ['websocket']});
    socket.on('connect', this.handleConnect);
    socket.emit('dashboard', { owner: 'fcab', station: station.station_code + '#' + sensor, index: index});
    socket.on('message', (data) => { this.handleEvent(data, index, index_sensor); } );
    socket.on('disconnect', this.handleDisconnect);
    socket.on('connect_error', this.handleError);
    socket.on('connect_timeout', this.handleError);
    this.sleep(index, index_sensor, 10);
    return socket;
  }

  handleConnect = () => {
    console.log('socket connected');
    this.setState({ errorMessage: '' });
  }
  handleEvent = (data, index, index_sensor) => {
    console.log(data);
    //console.log('socket event: ' + JSON.stringify(data));
    //console.log('clear: ' + index);
    clearTimeout(this.timers[index][index_sensor]);
    const { stations } = this.state;
    const station = stations[index];
    let alert = 'GREEN';
    for (var [key, sensor] of Object.entries(station.sensors)) {
      sensor.state = data.sensor.color;
      sensor.Hum_SHT = data.Hum_SHT;
      sensor.TempC_DS = data.TempC_DS;
      sensor.TempC_SHT = data.TempC_SHT;
      sensor.data_date = data.data_date;
      sensor.color = data.color;
      if (data.color === 'YELLOW' && alert === 'GREEN') {
        alert = 'YELLOW';
      } else
      if (data.color === 'RED' && alert !== 'RED') {
        alert = 'RED';
      }
    }

    const {markers} = this.state;
    markers[index] = station;
    markers[index].color = alert;
    this.setState({ stations, markers });
    this.sleep(index, index_sensor, 10);
  }
  handleDisconnect = () => {
    console.log('socket disconnected');
  }
  handleError = () => {
    console.log('socket error');
    this.setState({ errorMessage: 'Error de conexión al servidor' });
  }

  renderMarkers() {
    const { google } = this.props;
    const newBounds = new google.maps.LatLngBounds();
    const map = this.map ? this.map.map : {};
    const length = this.state.markers.length;
    return (
      this.state.markers.map((val, index) => {
        if (val && val.lat && val.lng) {
          const lastPosition = {lat: val.lat, lng: val.lng};
          const color = val.color ? val.color.toLowerCase() : 'blue';
          newBounds.extend(lastPosition);
          map.fitBounds(newBounds);
          return <Marker title={val.data_date} key={val.data_date} label={val.sensor} position={lastPosition} icon={{url: `https://maps.google.com/mapfiles/ms/icons/${color}-dot.png`}}/>
        }
        return '';
      })
    );
  }

  render() {
    const style = {
  	  width: 'calc(100% - 30px)',
  	  height: (window.innerHeight - 220) + 'px',
      position: 'relative'
  	}
    const initialCenter = {
      lat: 0,
      lng: 0
    }
    return (
      <Panel>
        <Panel.Heading>
          Monitoreo en línea
        </Panel.Heading>
        <Panel.Body>
          <Map
            google={this.props.google}
            style={style}
            initialCenter={initialCenter}
            centerAroundCurrentLocation={true}
            ref={(map) => this.map = map}
            zoom={10}>
            {this.renderMarkers()}
          </Map>
        </Panel.Body>
      </Panel>
    );
  }
}

export default GoogleApiWrapper({
	  apiKey: (config.API_GOOGLE_MAPS)
})(Dashboard)

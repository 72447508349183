const dev = {
  ga_analytics: '',
  s3: {
    BUCKET: "files.iot.neokodelabs.com"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://api.iot.neokodelabs.com"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_OoR4nk64g",
    APP_CLIENT_ID: "7akcj7feab7asaen4nsvon88r7",
    IDENTITY_POOL_ID: "us-west-2:8537fd0d-e763-44c1-9388-48ad90f72dec"
  },
  websocket: {
    //URL: "http://34.222.48.241:8000"
    //URL: "http://18.236.84.213:8000"
    //URL: "http://localhost:8000"
    URL: "https://ws.iot.neokodelabs.com"
  },
  API_GOOGLE_MAPS: 'AIzaSyC1CWqE4DTRICoXgMGZXrRYnxl3rGmFDuA',
  terms: 'http://iot.neokodelabs.com/terms',
  policy: 'http://iot.neokodelabs.com/policy'
};

const prod = {
  ga_analytics: 'UA-140385946-2',
  s3: {
    BUCKET: "files.iot.neokode.cl"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.iot.neokode.cl"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_<POOL_ID>",
    APP_CLIENT_ID: "<CLIENT_ID>",
    IDENTITY_POOL_ID: "us-east-1:<IDENTITY_ID>"
  },
  websocket: {
    URL: "https://ws.iot.neokode.cl"
  },
  API_GOOGLE_MAPS: 'AIzaSyC1CWqE4DTRICoXgMGZXrRYnxl3rGmFDuA',
  terms: 'http://iot.neokode.cl/terms',
  policy: 'http://iot.neokode.cl/policy'
};

const config = process.env.REACT_APP_ENV === 'production'
  ? prod
  : dev;

console.log("REACT_APP_ENV:" + process.env.REACT_APP_ENV);

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};

import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

function Events(props) {
    return (
        <BootstrapTable data={props.data} selectRow={props.selectRowProp} options={props.tableOptions} exportCSV csvFileName='eventos.csv' pagination>
            <TableHeaderColumn isKey={true} dataField='data_date' csvHeader='Fecha' csvFormat={props.creationDateFormatter} dataSort={true} dataFormat={props.creationDateFormatter}>Fecha</TableHeaderColumn>
            <TableHeaderColumn dataField='station_name' csvHeader='Estación' filter={{ type: 'TextFilter', delay: 10, placeholder: 'Ingresa estación...' }} dataSort={true}>Estación</TableHeaderColumn>
            <TableHeaderColumn dataField='sensor' csvHeader='Riel' filter={{ type: 'TextFilter', delay: 10, placeholder: 'Ingresa Riel...' }} dataSort={true}>Riel</TableHeaderColumn>
            <TableHeaderColumn dataField='TempC_DS' csvHeader='T° Riel' tdStyle={{ textAlign: "right" }} filter={{ type: 'TextFilter', delay: 10, placeholder: 'Ingresa Temp...' }} dataSort={true}>T° Riel</TableHeaderColumn>
            <TableHeaderColumn dataField='TempC_SHT' csvHeader='T° Ambiente' tdStyle={{ textAlign: "right" }} filter={{ type: 'TextFilter', delay: 10, placeholder: 'Ingresa Temp...' }} dataSort={true}>T° Ambiente</TableHeaderColumn>
            <TableHeaderColumn dataField='Hum_SHT' csvHeader='Humedad' tdStyle={{ textAlign: "right" }} filter={{ type: 'TextFilter', delay: 10, placeholder: 'Ingresa Humedad' }} dataSort={true}>Humedad</TableHeaderColumn>
        </BootstrapTable>
    )
}

export default Events;


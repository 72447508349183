import React, { Component } from "react";
import { invokeApig } from '../../libs/awsLib';
import { Glyphicon, Panel } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn,Form, FormGroup, ControlLabel } from 'react-bootstrap-table';
import Select from 'react-select';
import moment from 'moment';
import io from 'socket.io-client';
import config from '../../config';
import { getUserAttributes } from "../../libs/awsLib";
import './LastAlerts.css';

export default class LastAlerts extends Component {
  socket;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      errorMessage: null,
      data: [],
      lastUpdate: '',
      isLoadingStations: false,
      optionsStations: []
    };
  }

  componentDidMount() {
    this.updateStations();
  }

  updateStations = async () => {
    this.setState({ isLoadingStations: true });
    this.stations().then((response) => {
      var stations = [{value: null, label: 'Todas'}];
      response.stations.map((station, index) => {
        var sensors = [];
        for (var [key, value] of Object.entries(station.sensors)) {
          sensors.push({ value: key, label: value.name });
        }
        stations.push({ value: station.station_code, label: station.station_name, sensors: sensors });
        return null;
      });
      this.setState({ optionsStations: stations, isLoadingStations: false });
    }, (error) => {
      this.setState({ errorMessage: "Error al actualizar las estaciones. Favor intenta más tarde.", isLoadingStations: false});
    });
  }

  stations() {
    return invokeApig({
        path: "/station/find",
        method: "POST",
        body: {}
    });
  }

  handleChangeStation = (selectedStations) => {
    this.setState({ selectedStations});
    this.updateAlerts();
  }

  /* API alertas */
  updateAlerts = async () => {
    this.setState({ isLoading: true, data:[]});
    this.alerts().then((response) => {
      const date = response.date ? moment(response.date).utcOffset(response.dateZone) : moment(new Date());
      const lastUpdate = date.format("YYYY-MM-DD HH:mm:ss");
      if (response.errorMessage) {
        this.setState({ errorMessage: "Error al obtener las alertas.", lastUpdate: lastUpdate, isLoading: false });
      } else {
        this.setState({ data: response.response, lastUpdate: lastUpdate, isLoading: false });
        this.createSocket();
      }
    }, (error) => {
      this.setState({ errorMessage: "Error al actualizar las alertas.", isLoading: false });
    });
  }

  alerts() {
    return invokeApig({
      path: "/report/alerts",
      method: "POST",
      body: {
        dates: {
          startDate: moment().subtract(1, 'days').format(),
          endDate: moment().format(),
          timezone: '-03:00'
        },
        limit:20
      }
    });
  }

  /* Socket */
  createSocket = async () => {
    const user = await getUserAttributes();
    var socket = io(config.websocket.URL, { secure: true, transports: ['websocket'] });
    socket.on('connect', this.handleConnect);
    socket.emit('alerts', { owner: 'fcab' });
    socket.on('message', this.handleEvent);
    socket.on('disconnect', this.handleDisconnect);
    socket.on('connect_error', this.handleError);
    socket.on('connect_timeout', this.handleError);
  }
  handleConnect = () => {
    console.log('socket alerts connected');
    this.setState({ errorMessage: '' });
  }
  handleEvent = (data) => {
    console.log('socket alerts event: ' + JSON.stringify(data));
    const alerts = this.state.data.slice();
    alerts.unshift(data);
    if (alerts.length > 15) {
      alerts.pop();
    }
    this.setState({ data: alerts });
  }
  handleDisconnect = () => {
    console.log('socket alerts disconnected');
  }
  handleError = () => {
    console.log('socket alerts error');
    this.setState({ errorMessage: 'Error de conexión al servidor' });
  }

  /* Vista */
  setTableOption() {
    if (this.state.isLoading) {
      return <div><Glyphicon glyph="refresh" className="spinning" />Cargando últimas alertas... por favor espera</div>;
    } else {
      if (this.state.errorMessage) {
        return "Error al obtener alertas";
      } else {
        return "No existen alertas";
      }
    }
  }

  creationDateFormatter = (cell, row) => {
    const date = moment(cell); //.utcOffset(row.creationDateZone);
    return date.format("YYYY-MM-DD HH:mm:ss");
  }

  getStatusFormatter = (cell, row) => {
    if (row.color === 'YELLOW') {
      return <div style={{color: "#FFD733"}}><strong>{cell}</strong></div>
    }
    if (row.color === 'RED') {
      return <div style={{color: "red"}}><strong>{cell}</strong></div>
    }
    return <div style={{color: "green"}}><strong>{cell}</strong></div>
  }

  renderFilters() {
    const {selectedStations } = this.state;
    return (
      <div>
        <Form inline>
          <FormGroup controlId="station">
            <ControlLabel>Estaciones (*)</ControlLabel>{' '}
            <Select value={selectedStations} onChange={this.handleChangeStation} options={this.state.optionsStations} className="Select" placeholder="Selecciona..." isLoading={this.state.isLoadingStations} loadingMessage={()=>{return "Cargando...";}} noOptionsMessage={() => {return "No hay estaciones";}}/>
          </FormGroup>{' '}
          {/* {
            this.state.errorMessage &&
            <Alert bsStyle="danger" onDismiss={this.handleDismiss}>
              { this.state.errorMessage }
            </Alert>
          } */}
        </Form>
      </div>
    );
  }

  render() {
    const selectRowProp = {
      mode: 'radio',
      bgColor: 'pink',
      hideSelectColumn: true,
      clickToSelect: true
    };
    const tableOptions = {
      noDataText: this.setTableOption()
    };
    const { data, selectedStations } = this.state;
    return (
      <Panel>
        <Panel.Heading>
          Últimas alertas
        </Panel.Heading>
        <Panel.Body>
          <Select value={selectedStations} onChange={this.handleChangeStation} options={this.state.optionsStations} className="Select" placeholder="Selecciona Estación..." isLoading={this.state.isLoadingStations} loadingMessage={()=>{return "Cargando...";}} noOptionsMessage={() => {return "No hay estaciones";}}/>
          <BootstrapTable data={data} selectRow={selectRowProp} options={tableOptions} className="lastAlerts">
            <TableHeaderColumn row='0' colSpan='4'><span className="lastUpdate">Actualización: {this.state.lastUpdate}</span></TableHeaderColumn>
            <TableHeaderColumn row='1' isKey={true} dataField='data_date' dataFormat={this.creationDateFormatter} width="27%">Fecha</TableHeaderColumn>
            <TableHeaderColumn row='1' dataField='station_name'>Estación</TableHeaderColumn>
            <TableHeaderColumn row='1' dataField='sensor' width="18%">Sensor</TableHeaderColumn>
            <TableHeaderColumn row='1' dataField='TempC_DS' dataFormat={this.getStatusFormatter} width="17%"  tdStyle={{textAlign:"right"}}>Temp. Riel</TableHeaderColumn>
          </BootstrapTable>
        </Panel.Body>
      </Panel>
    );
  }

}

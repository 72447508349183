export function validateEmail(text) {
  return /^[a-zA-Z]+[a-zA-Z0-9.\-_]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(text);
}
export function validateNumber(text) {
  var re = new RegExp('^[0-9]+$');
  return re.test(text);
}
export function validateText(text, ini = 2, end = 64) {
  var re = new RegExp('^[a-zA-ZáéíóóÁÉÍÓÚ]{' + ini + ',' + end + '}$');
  return re.test(text);
}
export function validatePhone(text) {
  return /^[+]{1}[0-9]{3,16}$/.test(text);
}
export function validatePassword(text) {
  return /(?=.*[a-z])/.test(text) && /(?=.*[A-Z])/.test(text) && /(?=.*[0-9])/.test(text) && /(?=.{8,})/.test(text);
}

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
//import registerServiceWorker from "./registerServiceWorker";
import unregisterServiceWorker from './registerServiceWorker';
import config from './config';
// GA
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import "./index.css";

export default createBrowserHistory({});

ReactGA.initialize(config.ga_analytics);

const hist = createBrowserHistory();
hist.listen(location => {
	ReactGA.set({ page: location.pathname })
	ReactGA.pageview(location.pathname + location.search)
});

ReactDOM.render(
  <Router history={hist}>
    <App />
  </Router>,
  document.getElementById("root")
);
//registerServiceWorker();
unregisterServiceWorker();

import React from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

function Alerts(props){
    return(
        <BootstrapTable key='data_date' data={ props.data } selectRow={ props.selectRowProp } options={props.tableOptions} exportCSV csvFileName='alertas.csv' pagination>
            <TableHeaderColumn isKey={true} dataField='data_date' csvHeader='Fecha' csvFormat={props.creationDateFormatter} dataSort={ true } dataFormat={ props.creationDateFormatter }>Fecha</TableHeaderColumn>
            <TableHeaderColumn dataField='station_name' csvHeader='Estación' filter={ { type: 'TextFilter', delay: 10, placeholder: 'Ingresa estación...' } }  dataSort={ true }>Estación</TableHeaderColumn>
            <TableHeaderColumn dataField='sensor' csvHeader='Riel' filter={ { type: 'TextFilter', delay: 10, placeholder: 'Ingresa Riel...' } }  dataSort={ true }>Riel</TableHeaderColumn>
            <TableHeaderColumn dataField='TempC_DS'  csvHeader='Temp. Riel'tdStyle={{textAlign:"right"}} filter={ { type: 'TextFilter', delay: 10, placeholder: 'Ingresa Temp...' } } dataSort={ true }>Temp. Riel</TableHeaderColumn>
            <TableHeaderColumn dataField='color' csvHeader='Estado' dataFormat={ props.getStatusFormatter} filter={ { type: 'TextFilter', delay: 10, placeholder: 'Ingresa estado...' } } dataSort={ true }>Estado</TableHeaderColumn>
        </BootstrapTable>
    )
}

export default Alerts;


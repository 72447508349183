import React, { Component } from "react";
import { Link } from "react-router-dom";
import { invokeApig } from '../../libs/awsLib';
import { Breadcrumb, Glyphicon, Modal, Form, FormGroup, FormControl, Panel, Grid, Col, Row, Button, Alert } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Joyride from 'react-joyride';
import moment from 'moment';

export default class SupportDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
        	...props.location.state,
            isLoading: true,
            isLoadingReopen: false,
            isLoadingReply: false,
            isLoadingClose: false,
            isLoadingSend: false,
            showAlertError: false,
            alertMessage: '',
            comments:[],
            showReply: false,
            showReplyError: false,
            replyMessage: '',
            reply: '',
            runJoy: false,
            steps: [
          	  {
                target: '.panel-info',
                content: 'En esta sección se muestran datos de la creación del ticket.',
                placement: 'top'
              },
              {
                target: '.commentsTitle .col-md-4',
                content: 'Puedes reabrir el ticket si está cerrado, cerrarlo si estás conforme con la solución o responder a un comentario de soporte.',
                placement: 'top'
              },
              {
                target: '.user.row',
                content: 'Acá tienes los comentarios del ticket.',
                placement: 'top'
              }
            ]
        };
    }

    async componentDidMount() {
        try {
        	const results = await this.comments();
          this.setState({ comments: results, isLoading: false });
        } catch (e) {
        	this.handleAlertError('Ha ocurrido un error en cargar los comentarios. Favor intenta nuevamente.', { isLoading: false });
        }
    }

    comments() {
        return invokeApig({
            path: `/support/${this.state.id}`,
        });
    }
    //reopen ticket
    changeState(status) {
    	return invokeApig({
            path: `/support/state`,
            method: 'POST',
            body: {
            	id: this.state.id,
            	status: status
            }
        });
    }
    handleHelp = () => {
      this.setState({ runJoy: true});
    }
    handleReopenTicket = async () => {
    	this.setState({ isLoadingReopen: true });
    	let response = null;
    	try {
    		response = await this.changeState('En progreso');
    	} catch (e) {
        	response = false;
        }
    	if (response) {
    		this.setState({ isLoadingReopen: false, status: 'En progreso' });
    	} else {
    		this.handleAlertError('Error al reabrir el ticket', { isLoadingReopen: false });
    	}
    }
    //reply ticket
    handleReplyTicket = () => {
    	this.setState({ isLoadingReply: true, showReply: true });
    }
    sendReply() {
    	return invokeApig({
            path: `/support`,
            method: 'PUT',
            body: {
            	id: this.state.id,
            	message: this.state.reply
            }
        });
    }
    validateForm() {
      return (this.state.reply.length > 0);
    }
    handleDismissReplyError = () => {
      this.setState({showReplyError: false, replyMessage: ''});
    }
    handleSubmit = async event => {
      event.preventDefault();
      if (!this.validateForm()) {
      	this.setState({showReplyError: true, replyMessage: 'Debe ingresar el comentario'});
      	return;
      }
    	this.setState({ isLoadingSend: true });
    	let response = null;
    	try {
    		response = await this.sendReply();
    	} catch (e) {
        response = false;
      }
    	if (response) {
    		this.handleClose(true);
    	} else {
    		this.handleAlertError('Error al enviar comentario', { isLoadingSend: false });
    	}
    }
    handleClose = async (refresh) => {
        if (refresh) {
        	this.setState({ isLoadingReply: false, isLoadingSend: false, showReply: false, reply: '', isLoading: true });
        	await this.componentDidMount();
        } else {
        	this.setState({ isLoadingReply: false, isLoadingSend: false, showReply: false, reply: '' });
        }
    }
    handleChange = event => {
        this.setState({
          [event.target.id]: event.target.value
        });
    }
    //close ticket
    handleCloseTicket = async () => {
    	this.setState({ isLoadingClose: true });
    	let response = null;
    	try {
    		response = await this.changeState('Resuelto');
    	} catch (e) {
        	response = false;
        }
    	if (response) {
    		this.setState({ isLoadingClose: false, status: 'Resuelto' });
    	} else {
    		this.handleAlertError('Error al cerrar el ticket', { isLoadingClose: false });
    	}
    }
    handleAlertError = (message, state) => {
      this.setState({ ...state, alertMessage: message, showAlertError: true });
    }
    handleDismissError = () => {
      this.setState({ showAlertError: false });
    }
    showReplyDialog() {
    	return (
    		<Modal show={this.state.showReply} onHide={this.handleClose}>
	    	  <Modal.Header closeButton>
	            <Modal.Title>Ticket {}</Modal.Title>
	          </Modal.Header>
	          <Modal.Body>
	            <Form horizontal onSubmit={this.handleSubmit}>
	              <FormGroup controlId="reply">
	              	<FormControl
                    componentClass="textarea"
	          				onChange={this.handleChange}
                    value={this.state.reply}
                    placeholder="Escriba su respuesta..." />
                </FormGroup>
                {
                  this.state.showReplyError &&
                  <Alert bsStyle="danger" onDismiss={this.handleDismissReplyError}>
                    <strong>{this.state.replyMessage}</strong>
                  </Alert>
                }
                <LoaderButton
                  block
                  bsStyle="primary"
                  bsSize="large"
                  type="submit"
                  isLoading={this.state.isLoadingSend}
                  text="Enviar"
                  loadingText="Enviando…" />
	            </Form>
        	  </Modal.Body>
	          <Modal.Footer>
	            <Button onClick={this.handleClose}>Cerrar</Button>
	          </Modal.Footer>
    		</Modal>
    	);
    }
    renderComment(comment) {
      const creationDate = moment(comment.creationDate).utcOffset(comment.creationDateZone).format("YYYY-MM-DD HH:mm:ss");
    	return (
			<Grid key={creationDate} className="comment">
			  <Row className={comment.type}>
			    <Col md={3} className='userData'>
			    	<b>{comment.username}</b><br/>{creationDate}
			    </Col>
			    <Col md={9} className='message'>
			    	{comment.message}
			    </Col>
			  </Row>
			</Grid>
    	);
    }
    render(){
      const { steps, runJoy } = this.state;
      const data = this.state.comments;
      return(
			<div className="SupportDetail">
      <Breadcrumb>
        <Breadcrumb.Item componentClass={Link} href="/" to="/">Tablero</Breadcrumb.Item>
        <Breadcrumb.Item componentClass={Link} href="/support" to="/support">Soporte</Breadcrumb.Item>
          <Breadcrumb.Item active>Detalle ticket</Breadcrumb.Item>
          <Glyphicon className="help" glyph="info-sign" onClick={this.handleHelp} />
        </Breadcrumb>
        { this.state.showReply && this.showReplyDialog() }
        <Panel bsStyle="info">
          <Panel.Heading>
            <Panel.Title componentClass="h3">Datos del Ticket {this.state.id}</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <Col md={12}>
                <Col md={2} className='lblTicket'><strong>Usuario</strong></Col>
                <Col md={10}>{this.state.username}</Col>
            </Col>
            <Col md={12}>
                <Col md={2} className='lblTicket'><strong>Tipo</strong></Col>
                <Col md={4}>{this.state.cause}</Col>
                <Col md={2} className='lblTicket'><strong>Estado </strong></Col>
                <Col md={4}>{this.state.status}</Col>
            </Col>
            <Col md={12}>
                <Col md={2} className='lblTicket'><strong>Creado</strong></Col>
                <Col md={4}>{moment(this.state.creationDate).utcOffset(this.state.creationDateZone).format("YYYY-MM-DD HH:mm")}</Col>
                <Col md={2} className='lblTicket'><strong>Última modificación</strong></Col>
                <Col md={4}>{moment(this.state.lastUpdateDate).utcOffset(this.state.lastUpdateDateZone).format("YYYY-MM-DD HH:mm")}</Col>
            </Col>
            <Col md={12}>
              <Col md={2} className='lblTicket'><strong>Mensaje</strong></Col>
              <Col md={10} className='message'>{this.state.message}</Col>
            </Col>
          </Panel.Body>
        </Panel>
        {
          this.state.showAlertError &&
          <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
            <strong>{this.state.alertMessage}</strong>
          </Alert>
        }
        <Grid className="commentsTitle">
          <Row>
            <Col md={8}>
              <h3>Comentarios</h3>
            </Col>
            <Col md={4}>
              {this.state.status === 'Resuelto' ?
              <Button onClick={this.handleReopenTicket} disabled={this.state.isLoadingReopen}>{this.state.isLoadingReopen ? <Glyphicon glyph="refresh" className="spinning" /> : "Reabrir"}</Button> :
              <div>
                <Button onClick={this.handleReplyTicket} disabled={this.state.isLoadingReply}>{this.state.isLoadingReply ? <Glyphicon glyph="refresh" className="spinning" /> : "Responder"}</Button>
                <Button onClick={this.handleCloseTicket} disabled={this.state.isLoadingClose}>{this.state.isLoadingClose ? <Glyphicon glyph="refresh" className="spinning" /> : "Cerrar ticket"}</Button>
              </div>
              }
            </Col>
          </Row>
        </Grid>
	      <hr/>
        {
      	  this.state.isLoading ?
				  <div><Glyphicon glyph="refresh" className="spinning" />Cargando comentarios... por favor espere</div>
          :
          (
        		data.length ?
        		data.map((comment) => { return this.renderComment(comment); })
            :
      	    "No hay comentarios"
      	  )
        }
        <Joyride
  		    steps={steps}
  		    run={runJoy}
          continuous={true}
          scrollToFirstStep={true}
          callback={this.callback}
          locale={{ back: 'Atrás', close: 'Cerrar', last: 'Finalizar', next: 'Siguiente', skip: 'Saltar' }}
		      />
      </div>
      );
    }
}

import React, { Component } from "react";
import DatePicker from 'react-datepicker';
import '../../node_modules/react-datepicker/dist/react-datepicker.css';
import { Button, Modal, Tabs, Tab, Table, ButtonToolbar, ToggleButtonGroup, ToggleButton, Glyphicon } from "react-bootstrap";
import moment from 'moment';
import 'moment/locale/es';

export default class AppDatePicker extends Component {
  constructor(props) {
    super(props);
    const timezone = props.timezone ? props.timezone : '+0000';
    const endDate = props.endDate ? moment(props.endDate).utcOffset(timezone) : moment().utcOffset(timezone);
    const startDate = props.startDate ? moment(props.startDate).utcOffset(timezone) : moment().utcOffset(timezone).add(-1, 'day');
    this.state = {
      startDate: startDate,
      endDate: endDate,
      timezone: timezone,
      date: 'Últimos 1  día: ' + startDate.format("YYYY-MM-DD HH:mm") + " - " + endDate.format("YYYY-MM-DD HH:mm"),
      show: false,
      tab: 1,
      relValue: null,
      showLoading: this.props.showLoading
    };
  }

  componentDidMount(){
	if (this.props.onSelect) {
  	  this.props.onSelect({ startDate: this.state.startDate, endDate: this.state.endDate, timezone: this.state.timezone });
  	}
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.startDate && nextProps.endDate) {
      const timezone = nextProps.timezone;
      const endDate = moment(nextProps.endDate).utcOffset(timezone)
      const startDate = moment(nextProps.startDate).utcOffset(timezone)
      this.setState({
        startDate: startDate,
        endDate: endDate,
        timezone: timezone,
        date: startDate.format("YYYY-MM-DD HH:mm") + " - " + endDate.format("YYYY-MM-DD HH:mm"),
        showLoading: false
      });
    }
  }

  handleShow = event => {
    this.setState({ show: true });
  }

  handleClose = event => {
	   this.setState({ show: false });
  }

  handleChangeStart = (date) => {
    this.setState({ startDate: date });
  }

  handleChangeEnd = (date) => {
    this.setState({ endDate: date });
  }

  handleRelChange = (e) => {
    this.setState({ relValue: e });
  }

  handleSelect = (event) => {
    if (!event) {
      alert('Debes seleccionar una fecha');
    }
    let startDate = null, endDate = null, timezone = this.state.timezone;
  	if (this.state.tab === 1) {
  	  this.setState({ show: false, date: 'Personalizado: ' + this.state.startDate.format("YYYY-MM-DD HH:mm") + " - " + this.state.endDate.format("YYYY-MM-DD HH:mm") });
      startDate = this.state.startDate;
      endDate = this.state.endDate;
  	} else {
  	  var value = parseInt(this.state.relValue.substring(0, this.state.relValue.length - 1), 10);
  	  var dateMsg = null;
      endDate = moment().utcOffset(timezone);
  	  switch(this.state.relValue.charAt(this.state.relValue.length  - 1)) {
  	  case 'm':
  		  dateMsg = 'Últimos ' + value + ' minutos: ';
  		  startDate = moment().utcOffset(timezone).add(parseInt(value, 10) * -1, 'minute');
  		  break;
  	  case 'h':
  		  if (value === '1') {
  		    dateMsg = 'Última hora: ';
  		  } else {
  			dateMsg = 'Últimas ' + value + ' horas: ';
  		  }
  		  startDate = moment().utcOffset(timezone).add(parseInt(value, 10) * -1, 'hour');
  		  break;
  	  case 'd':
  		  if (value === '1') {
  		    dateMsg = 'Último día: ';
  		  } else {
  			dateMsg = 'Últimos ' + value + ' días: ';
  		  }
  		  startDate = moment().utcOffset(timezone).add(parseInt(value, 10) * -1, 'day');
  		  break;
  	  case 'w':
  		  if (value === '1') {
  		    dateMsg = 'Última semana: ';
  		  } else {
  			dateMsg = 'Últimas ' + value + ' semanas: ';
  		  }
  		  startDate = moment().utcOffset(timezone).add(parseInt(value, 10) * -1, 'week');
  		  break;
  	  default:
  		  dateMsg = 'Últimos 30 días: ';
  		  startDate = moment().utcOffset(timezone).add(-30, 'day');
  		  break;
  	  }
  	  dateMsg = dateMsg + startDate.format("YYYY-MM-DD HH:mm") + " - " + endDate.format("YYYY-MM-DD HH:mm");
  	  this.setState({ show: false, date: dateMsg });
  	}
  	if (this.props.onSelect) {
  	  this.props.onSelect({ startDate: startDate, endDate: endDate, timezone: timezone });
  	}
  }

  handleSelectTab = (key) => {
	   this.setState({ tab: key });
  }

  render() {
    return (
	  <div className="AppDatePicker">
	    <div className={this.props.className && this.props.className}>
		    <Button onClick={this.handleShow}>
		      {
            this.state.showLoading ?
            <Glyphicon glyph="refresh" className="spinning" />
            :
            this.state.date
          }
	      </Button>
	    </div>
	    <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Selección de rango de fecha</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs activeKey={this.state.tab} onSelect={this.handleSelectTab} id="selectDateTab">
	          <Tab eventKey={1} title="Absoluto">
	          	<DatePicker
		          selected={this.state.startDate}
		          selectsStart
		          startDate={this.state.startDate}
		          endDate={this.state.endDate}
		          onChange={this.handleChangeStart}
	          	  maxDate={this.state.endDate}
	          	  inline
			    />
			    <DatePicker
		          selected={this.state.endDate}
		          selectsEnd
              locale="es"
		          startDate={this.state.startDate}
		          endDate={this.state.endDate}
		          onChange={this.handleChangeEnd}
			      minDate={this.state.startDate}
			      inline
			    />
	          </Tab>
	          <Tab eventKey={2} title="Relativo">
	            <ButtonToolbar>
	              <Table responsive>
	              	<tbody>
	              	  <tr>
	              	  	<td>Minutos</td>
	              	  	<td>
		              	  <ToggleButtonGroup type="radio" name="relValue" value={this.state.relValue} onChange={this.handleRelChange}>
			                  <ToggleButton value={'5m'}>5</ToggleButton>
			                  <ToggleButton value={'10m'}>10</ToggleButton>
			                  <ToggleButton value={'15m'}>15</ToggleButton>
			                  <ToggleButton value={'30m'}>30</ToggleButton>
			                  <ToggleButton value={'45m'}>45</ToggleButton>
			              </ToggleButtonGroup>
				        </td>
				      </tr>
				      <tr>
	              	  	<td>Horas</td>
	              	  	<td>
	              	  	  <ToggleButtonGroup type="radio" name="relValue" value={this.state.relValue} onChange={this.handleRelChange}>
	              	  	    <ToggleButton value={'1h'}>1</ToggleButton>
	              	  	    <ToggleButton value={'3h'}>3</ToggleButton>
	              	  	    <ToggleButton value={'6h'}>6</ToggleButton>
	              	  	    <ToggleButton value={'8h'}>8</ToggleButton>
	              	  	    <ToggleButton value={'12h'}>12</ToggleButton>
			              </ToggleButtonGroup>
				        </td>
				      </tr>
				      <tr>
	              	  	<td>Días</td>
	              	  	<td>
		              	  <ToggleButtonGroup type="radio" name="relValue" value={this.state.relValue} onChange={this.handleRelChange}>
	            	  	    <ToggleButton value={'1d'}>1</ToggleButton>
	            	  	    <ToggleButton value={'2d'}>2</ToggleButton>
	            	  	    <ToggleButton value={'3d'}>3</ToggleButton>
	            	  	    <ToggleButton value={'4d'}>4</ToggleButton>
	            	  	    <ToggleButton value={'5d'}>5</ToggleButton>
	            	  	    <ToggleButton value={'6d'}>6</ToggleButton>
			              </ToggleButtonGroup>
				        </td>
				      </tr>
				      <tr>
	              	  	<td>Semanas</td>
	              	  	<td>
	              	  	  <ToggleButtonGroup type="radio" name="relValue" value={this.state.relValue} onChange={this.handleRelChange}>
	              	  	    <ToggleButton value={'1w'}>1</ToggleButton>
	              	  	    <ToggleButton value={'2w'}>2</ToggleButton>
	              	  	    <ToggleButton value={'3w'}>3</ToggleButton>
	              	  	    <ToggleButton value={'4w'}>4</ToggleButton>
			              </ToggleButtonGroup>
				        </td>
				      </tr>
				    </tbody>
				  </Table>
	            </ButtonToolbar>
	          </Tab>
	        </Tabs>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose}>Cerrar</Button>
            <Button onClick={this.handleSelect} bsStyle="primary">Seleccionar</Button>
          </Modal.Footer>
        </Modal>
	  </div>
	);
  }

}

import React, { Component } from "react";
import "./Analysis.css";
import { invokeApig } from '../../libs/awsLib';
import moment from 'moment';
import { Link } from "react-router-dom";
import { Breadcrumb, PageHeader, Glyphicon, Form, FormGroup, ControlLabel, Button, Alert } from "react-bootstrap";
import Select from 'react-select';
import AppDatePicker from '../../components/AppDatePicker';
import { Resizable, Charts, ChartContainer, ChartRow, YAxis, LineChart, Legend, Baseline, LabelAxis, ValueAxis, BoxChart, Brush, AreaChart, styler } from "react-timeseries-charts";
import { TimeSeries, TimeRange, avg, percentile, median } from "pondjs";
import Joyride from 'react-joyride';
import { format } from "d3-format";

const style = styler([
    { key: "TempC_SHT", color: "steelblue", width: 1, opacity: 0.5 },
    { key: "TempC_DS", color: "green", width: 1, opacity: 0.5 },
    { key: "Hum_SHT", color: "#ff47ff" },
    { key: "avg", color: "#e2e2e2" }
]);

const baselineStyles = {
    TempC_SHT: {
        stroke: "steelblue",
        opacity: 0.5,
        width: 0.25
    },
    TempC_DS: {
        stroke: "green",
        opacity: 0.5,
        width: 0.25
    }
};

export default class Analysis extends Component {
  constructor(props) {
    super(props);
    const initialRange = new TimeRange([75 * 60 * 1000, 125 * 60 * 1000]);

    const channels = {
        TempC_SHT: { units: "°", label: "Temp riel", format: ",.1f", series: null, show: true },
        TempC_DS: { units: "°", label: "Temp ambiente", format: ",.1f", series: null, show: true },
        Hum_SHT: { units: "%", label: "Humedad", format: "d", series: null, show: true },
        avg: { units: "°", label: "Temp media", format: "d", series: null, show: false }
    };

    const channelNames = ["TempC_SHT", "TempC_DS", "Hum_SHT", "avg"];

    const displayChannels = ["TempC_SHT", "TempC_DS", "Hum_SHT"];

    this.state = {
      isLoading: true,
      errorMessage: null,
      sensors: [],
      selectedStations: null,
      optionsStations: [],
      selectedSensors: null,
      optionsSensors: [],
      dates: null,

      ready: false,
      channels,
      channelNames,
      displayChannels,
      tracker: null,
      timerange: initialRange,
      brushrange: initialRange,

      runJoy: false,
      steps: [
        {
          target: '.Analysis .jumbotron',
          content: 'En esta sección podrás ver un análisis de un sensor',
          placement: 'bottom',
        }
      ]
    };
  }

  componentDidMount() {
    this.updateStations();
  }

  updateStations = async () => {
    this.setState({ isLoading: true });
    this.stations().then((response) => {
      var stations = [];
      response.stations.map((station, index) => {
        var sensors = [];
        for (var [key, value] of Object.entries(station.sensors)) {
          sensors.push({ value: key, label: value.name });
        }
        stations.push({ value: station.station_code, label: station.station_name, sensors: sensors });
        return null;
      });
      this.setState({ optionsStations: stations, isLoading: false });
    }, (error) => {
      this.setState({ errorMessage: "Error al actualizar las estaciones. Favor intenta más tarde.", isLoading: false});
    });
  }
  stations() {
      return invokeApig({
          path: "/station/find",
          method: "POST",
          body: {}
      });
  }

  handleChangeStation = (selectedStations) => {
    this.setState({ selectedStations, selectedSensors: null, optionsSensors: selectedStations.sensors });
  }

  handleChangeSensor = (selectedSensors) => {
    if (selectedSensors.length > 2) {
      this.setState({ errorMessage: 'Sólo puedes elegir 2 sensores' });
    } else {
      this.setState({ selectedSensors });
    }
  }

  handleSelectDates = (dates) => {
    this.setState({ dates });
  }

  loadChart(data) {
    setTimeout(() => {
        const { channelNames, channels, displayChannels } = this.state;

        const points = {};
        channelNames.forEach(channel => {
            points[channel] = [];
        });

        data.response.map((val, index) => {
          const timestamp = moment(new Date(`${val.data_date}`));
          console.log(timestamp);
          points["TempC_SHT"].push([timestamp, parseFloat(val.TempC_SHT)]);
          points["TempC_DS"].push([timestamp, parseFloat(val.TempC_DS)]);
          points["Hum_SHT"].push([timestamp, parseFloat(val.Hum_SHT)]);
          points["avg"].push([timestamp, parseFloat(val.TempC_SHT)]);
        });

        for (let channelName of channelNames) {
            // The TimeSeries itself, for this channel
            const series = new TimeSeries({
                name: channels[channelName].name,
                columns: ["time", channelName],
                points: points[channelName]
            });

            // Raw series
            channels[channelName].series = series;

            // Some simple statistics for each channel
            channels[channelName].avg = Math.round(parseFloat(series.avg(channelName), 10) * 10) / 10;
            channels[channelName].max = Math.round(parseFloat(series.max(channelName), 10) * 10) / 10;
        }

        // Min and max time constraints for pan/zoom, along with the smallest timerange
        // the user can zoom into. These are passed into the ChartContainers when we come to
        // rendering.

        const minTime = channels.avg.series.range().begin();
        const maxTime = channels.avg.series.range().end();
        const minDuration = 10 * 60 * 1000;

        this.setState({ ready: true, channels, minTime, maxTime, minDuration, timerange: channels["avg"].series.range(), brushrange: null });
    }, 0);
  }

  handleClick = async () => {
    const { selectedStations, selectedSensors, dates } = this.state;
    if (!selectedStations || !selectedSensors || !dates) {
      this.setState({errorMessage: 'Debes seleccionar los filtros'});
      return;
    }
    this.setState({errorMessage: null, isLoading: true, data: []});
    var sensors = [];
    selectedSensors.map((sensor, index) => {
      sensors.push(sensor.value);
      return sensor;
    });
    const request = {
      station: selectedStations.value,
      sensors: sensors,
      startDate: dates.startDate.utc().format(),
      endDate: dates.endDate.utc().format(),
      timezone: dates.timezone
    };
    const sensor1 = selectedSensors[0].name;
    const temps = [[], []];
    this.get_data(request).then((response) => {
      this.loadChart(response);
      this.setState({isLoading: false});
    }, (error) => {
      console.log(error);
      this.setState({ isLoading: false, errorMessage: 'Error al obtener los datos de los sensores. Intenta nuevamente.' });
    });
  }

  get_data(request) {
      return invokeApig({
          path: "/values/find",
          method: "POST",
          body: request
      });
  }

  handleHelp = () => {
    this.setState({runJoy: true});
  }

  handleDismiss = () => {
    this.setState({errorMessage: null});
  }
  dateFormatter = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  }



  handleTrackerChanged = t => {
      this.setState({ tracker: t });
  };

  // Handles when the brush changes the timerange
  handleTimeRangeChange = timerange => {
      const { channels } = this.state;

      if (timerange) {
          this.setState({ timerange, brushrange: timerange });
      } else {
          this.setState({ timerange: channels["avg"].series.range(), brushrange: null });
      }
  };

  handleChartResize = width => {
      this.setState({ width });
  };

  handleActiveChange = channelName => {
      const channels = this.state.channels;
      channels[channelName].show = !channels[channelName].show;
      this.setState({ channels });
  };

  renderChannelsChart = () => {
      const { timerange, displayChannels, channels, maxTime, minTime, minDuration } = this.state;

      const durationPerPixel = timerange.duration() / 800 / 1000;
      const rows = [];

      for (let channelName of displayChannels) {
          const charts = [];
          let series = channels[channelName].series;

          charts.push(
              <LineChart
                  key={`line-${channelName}`}
                  axis={`${channelName}_axis`}
                  series={series}
                  columns={[channelName]}
                  style={style}
                  breakLine
              />
          );
          charts.push(
              <Baseline
                  key={`baseline-${channelName}`}
                  axis={`${channelName}_axis`}
                  style={baselineStyles.speed}
                  value={channels[channelName].avg}
              />
          );

          // Get the value at the current tracker position for the ValueAxis
          let value = "--";
          if (this.state.tracker) {
              const approx =
                  (+this.state.tracker - +timerange.begin()) /
                  (+timerange.end() - +timerange.begin());
              const ii = Math.floor(approx * series.size());
              const i = series.bisect(new Date(this.state.tracker), ii);
              const v = i < series.size() ? series.at(i).get(channelName) : null;
              if (v) {
                  value = parseFloat(v, 10);
              }
          }

          // Get the summary values for the LabelAxis
          const summary = [
              { label: "Max", value: channels[channelName].max },
              { label: "Prom", value: channels[channelName].avg }
          ];

          rows.push(
              <ChartRow
                  height="100"
                  visible={channels[channelName].show}
                  key={`row-${channelName}`}
              >
                  <LabelAxis
                      id={`${channelName}_axis`}
                      label={channels[channelName].label}
                      values={summary}
                      min={0}
                      max={channels[channelName].max}
                      width={140}
                      type="linear"
                      format=",.1f"
                  />
                  <Charts>{charts}</Charts>
                  <ValueAxis
                      id={`${channelName}_valueaxis`}
                      value={value}
                      detail={channels[channelName].units}
                      width={80}
                      min={0}
                      max={35}
                  />
              </ChartRow>
          );
      }

      return (
          <ChartContainer
              timeRange={this.state.timerange}
              format="relative"
              showGrid={false}
              enablePanZoom
              enableDragZoom
              maxTime={maxTime}
              minTime={minTime}
              minDuration={minDuration}
              trackerPosition={this.state.tracker}
              onTimeRangeChanged={this.handleTimeRangeChange}
              onChartResize={width => this.handleChartResize(width)}
              onTrackerChanged={this.handleTrackerChanged}
          >
              {rows}
          </ChartContainer>
      );
  };


  renderBrush = () => {
      const { channels } = this.state;
      return (
          <ChartContainer
              timeRange={channels.avg.series.range()}
              format="relative"
              trackerPosition={this.state.tracker}
          >
              <ChartRow height="100" debug={false}>
                  <Brush
                      timeRange={this.state.brushrange}
                      allowSelectionClear
                      onTimeRangeChanged={this.handleTimeRangeChange}
                  />
                  <YAxis
                      id="axis1"
                      label="Temperaturas (C)"
                      min={0}
                      max={channels.avg.max}
                      width={70}
                      type="linear"
                      format="d"
                  />
                  <Charts>
                      <AreaChart
                          axis="axis1"
                          style={style.areaChartStyle()}
                          columns={{ up: ["avg"], down: [] }}
                          series={channels.avg.series}
                      />
                  </Charts>
              </ChartRow>
          </ChartContainer>
      );
  };

  renderFilters() {
    const { selectedStations, selectedSensors } = this.state;
    return (
      <Form inline>
        <FormGroup controlId="station">
          <ControlLabel>Estaciones</ControlLabel>{' '}
          <Select value={selectedStations} onChange={this.handleChangeStation} options={this.state.optionsStations} className="Select" placeholder="Selecciona..." loadingMessage={()=>{return "Cargando...";}} noOptionsMessage={() => {return "No hay estaciones";}}/>
        </FormGroup>{' '}
        <FormGroup controlId="sensor">
          <ControlLabel>Sensores</ControlLabel>{' '}
          <Select value={selectedSensors} onChange={this.handleChangeSensor} options={this.state.optionsSensors} isMulti={true} className="Select" placeholder="Selecciona..." noOptionsMessage={() => {return "No hay sensores";}}/>
        </FormGroup>{' '}
        <FormGroup controlId="dates">
          <ControlLabel>Rango de fecha</ControlLabel>{' '}
          <AppDatePicker onSelect={this.handleSelectDates} className="AnalysisDate" timezone={this.props.timezone}/>
        </FormGroup>{' '}
        <Button type="button" onClick={this.handleClick} bsStyle="primary"><Glyphicon glyph="search" />&nbsp;&nbsp;Buscar</Button>
        {
          this.state.errorMessage &&
          <Alert bsStyle="danger" onDismiss={this.handleDismiss}>
            { this.state.errorMessage }
          </Alert>
        }
      </Form>
    );
  }
  renderChart() {
    //data_date
    //Hum_SHT Humedad
    //TempC_DS Temperatura Riel
    //TempC_SHT Temperatura Ambiente
    const { ready, channels, displayChannels } = this.state;

    if (!ready) {
        return <div>{``}</div>;
    }
    const chartStyle = {
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#DDD",
        paddingTop: 10,
        marginBottom: 10
    };

    const brushStyle = {
        boxShadow: "inset 0px 2px 5px -2px rgba(189, 189, 189, 0.75)",
        background: "#FEFEFE",
        paddingTop: 10
    };

    // Generate the legend
    const legend = displayChannels.map(channelName => ({
        key: channelName,
        label: channels[channelName].label,
        disabled: !channels[channelName].show
    }));

    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <hr />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <Legend
                        type="line"
                        style={style}
                        categories={legend}
                        onSelectionChange={this.handleActiveChange}
                    />
                </div>

                <div className="col-md-6">
                    {this.state.tracker
                        ? `${this.dateFormatter(+this.state.tracker)}`
                        : "-:--:--"}
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <hr />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12" style={chartStyle}>
                    <Resizable>
                        {ready ? this.renderChannelsChart() : <div>Loading.....</div>}
                    </Resizable>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12" style={brushStyle}>
                    <Resizable>{ready ? this.renderBrush() : <div />}</Resizable>
                </div>
            </div>
        </div>
    );
  }
  render() {
    const { steps, runJoy } = this.state;
    return (
      <div className="Analysis">
        <Breadcrumb>
          <Breadcrumb.Item componentClass={Link} href="/" to="/">Tablero</Breadcrumb.Item>
	        <Breadcrumb.Item active>Análisis</Breadcrumb.Item>
          <Glyphicon className="help" glyph="info-sign" onClick={this.handleHelp} />
        </Breadcrumb>
        { this.renderFilters() }
        { this.state.isLoading && <div><Glyphicon glyph="refresh" className="spinning" />Cargando datos... por favor espere</div> }
        {
          !this.state.isLoading && this.renderChart()
        }
        <Joyride
          steps={steps}
          run={runJoy}
          continuous={true}
          locale={{ back: 'Atrás', close: 'Cerrar', last: 'Finalizar', next: 'Siguiente', skip: 'Saltar' }}/>
      </div>
    );
  }

}

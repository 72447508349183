import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Col,
  Breadcrumb,
  PageHeader,
  Button,
  OverlayTrigger,
  Tooltip,
  Glyphicon,
  Modal,
  Alert
} from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { invokeApig, getAccessToken } from "../../libs/awsLib";
import Joyride from 'react-joyride';
import {validateEmail, validateText, validatePhone, validatePassword} from '../../libs/validation';

export class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: null,
      email:'',
      name:'',
      lastName: '',
      phoneNumber: '',
      status: '',
      showChangePassword: false,
      oldPassword: '',
      newPassword: '',
      newPassword2: '',
      showEdit: false,
      isLoadingProfile: true,
      showAlertSuccess: false,
      showAlertError: false,
      showAlertErrorChgPwd: false,
      showAlertErrorEdit: false,
      alertMessage: '',
      showAlertEditFormat: false,
      alertMessageEdit: '',
      runJoy: false,
      steps: [
    	  {
          target: '.form-horizontal',
          content: 'En esta sección se muestran tus datos actuales',
          placement: 'top'
        },
        {
          target: '.form-horizontal .btnChangePass',
          content: 'Puedes modificar tu clave haciendo click aqui',
          placement: 'top'
        },
        {
          target: '.form-horizontal .btnUpdateProfile',
          content: 'Puedes modificar tus datos haciendo click aqui',
          placement: 'top'
        }
      ]
    };
  }

  async componentDidMount() {
    try {
      const user = await this.getUser();
      this.setState({ ...user.data, isLoadingProfile: false });
    } catch(e) {
      this.handleAlertError('Tuvimos problemas al obtener tus datos, favor intenta más tarde.');
    }
  }

  getUser() {
    return invokeApig({
      path: `/user/profile`
    });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleChangePassword = () => {
    this.setState({ showChangePassword: true });
  }
  handleCloseChangePassword = () => {
    this.setState({ showChangePassword: false });
  }
  userChangePassword(request) {
    return invokeApig({
      path: `/user/profile/changePassword`,
      method: "PUT",
      body: request
    });
  }
  getErrorsChangePassword() {
    let message = undefined;
    if (!validatePassword(this.state.oldPassword)) {
      message = 'Debe ingresar su contraseña actual correctamente';
    } else {
      if (!validatePassword(this.state.newPassword)) {
        message = 'Debe ingresar su nueva contraseña correctamente. Debe contener minúscula, mayúscula, número, símbolo y mínimo 8 caracteres.';
      } else {
        if (this.state.newPassword !== this.state.newPassword2) {
          message = 'Las contraseñas nuevas no coinciden.';
        } else {
          if (this.state.newPassword === this.state.oldPassword) {
            message = 'La contraseña nueva debe ser distinta a la actual.';
          }
        }
      }
    }
    return message;
  }
  changePassword = async () => {
    const errorMsg = this.getErrorsChangePassword();
    if (errorMsg) {
      this.handleAlertErrorChgPwd(errorMsg);
      return;
    }
    const accessToken = await getAccessToken();
    try {
      const response = await this.userChangePassword({
        oldPassword: this.state.oldPassword,
        newPassword: this.state.newPassword,
        accessToken: accessToken
      });
      if (response && response.code === 0) {
        this.handleAlertSuccess('La clave se ha actualizado correctamente.', { showChangePassword: false, isLoading: false });
      } else {
        if (response.errorType === 'NotAuthorizedException') {
      	  this.handleAlertErrorChgPwd('Clave incorrecta');
      	} else {
      	  if (response.errorType === 'LimitExceededException') {
      		  this.handleAlertErrorChgPwd('Exceso de intentos. Vuelve más tarde.', { showChangePassword: false, isLoading: false });
      	  } else {
      		  this.handleAlertErrorChgPwd('Error al actualizar la clave. Verifique que contenga al menos 8 caracteres, letras, números y al menos un símbolo.');
      	  }
      	}
      }
    } catch(e) {
      this.handleAlertErrorChgPwd('Error al cambiar la clave. Verifique que haya ingresado bien su clave anterior y que la nueva sea segura.');
    }
  }

  //modificar
  handleEdit = () => {
    this.setState({ showEdit: true });
  }
  handleCloseEdit = () => {
    this.setState({ showEdit: false });
  }
  userEdit(request) {
    return invokeApig({
      path: `/user/profile`,
      method: "PUT",
      body: request
    });
  }
  getErrors() {
    let message = undefined;
    if (!validateEmail(this.state.email)) {
      message = 'Debe ingresar el email correctamente';
    } else {
      if (!validateText(this.state.name)) {
        message = 'Debe ingresar el nombre correctamente';
      } else {
        if (!validateText(this.state.lastName)) {
          message = 'Debe ingresar el apellido correctamente';
        } else {
          if (this.state.phoneNumber && !validatePhone(this.state.phoneNumber)) {
            message = 'Debe ingresar el teléfono correctamente: +01234567890';
          }
        }
      }
    }
    return message;
  }
  edit = async () => {
    const errorMsg = this.getErrors();
    if (errorMsg) {
      this.setState({showAlertEditFormat: true, alertMessageEdit: errorMsg});
      return;
    }
    const accessToken = await getAccessToken();
    try {
      const response = await this.userEdit({
        name: this.state.name,
        lastName: this.state.lastName,
        phoneNumber: this.state.phoneNumber,
        accessToken: accessToken
      });
      if (response.errorType) {
        this.handleAlertError('Error al actualizar el perfil.', { showEdit: false });
      } else {
        this.handleAlertSuccess('Se han actualizado tus datos correctamente.', { showEdit: false });
      }
    } catch(e) {
      this.handleAlertError('Error al actualizar el perfil.', { showEdit: false });
    }
    this.setState({showAlertEditFormat: false, alertMessageEdit: ''});
  }
  handleDismissEditFormat = () => {
    this.setState({ showAlertEditFormat: false, alertMessageEdit: '' });
  }
  handleAlertSuccess = (message, state) => {
    this.setState({ ...state, alertMessage: message, showAlertSuccess: true, showAlertError: false });
  }
  handleDismissSuccess = () => {
	  this.setState({ showAlertSuccess: false });
  }
  handleAlertError = (message, state) => {
    this.setState({ ...state, alertMessage: message, showAlertError: true, showAlertSuccess: false });
  }
  handleDismissError = () => {
	  this.setState({ showAlertError: false });
  }
  handleAlertErrorChgPwd = (message, state) => {
    this.setState({ ...state, alertMessage: message, showAlertErrorChgPwd: true });
  }
  handleDismissErrorChgPwd = () => {
	  this.setState({ showAlertErrorChgPwd: false });
  }
  handleHelp = () => {
    this.setState({ runJoy: true});
  }
  renderChangePasword(){
    return (
	  <Form horizontal onSubmit={this.handleSubmit}>
        <FormGroup controlId="oldPassword">
          <Col componentClass={ControlLabel} sm={3}>
            Contraseña actual
          </Col>
          <Col sm={8}>
            <FormControl onChange={this.handleChange} value={this.state.oldPassword} placeholder="*******" type="password" />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Contraseña actual.</Tooltip>}>
              <a tabIndex="-1"><Glyphicon glyph="info-sign"/></a>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="newPassword">
          <Col componentClass={ControlLabel} sm={3}>
            Contraseña nueva
          </Col>
          <Col sm={8}>
          	<FormControl onChange={this.handleChange} value={this.state.newPassword} placeholder="*******" type="password" />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Contraseña nueva.</Tooltip>}>
              <a tabIndex="-1"><Glyphicon glyph="info-sign"/></a>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="newPassword2">
	      <Col componentClass={ControlLabel} sm={3}>
	        Repetir contraseña nueva
	      </Col>
	      <Col sm={8}>
	      	<FormControl onChange={this.handleChange} value={this.state.newPassword2} placeholder="*******" type="password" />
	      </Col>
        <Col sm={1}>
          <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Repetir contraseña nueva.</Tooltip>}>
            <a tabIndex="-1"><Glyphicon glyph="info-sign"/></a>
          </OverlayTrigger>
        </Col>
	    </FormGroup>
      {
        this.state.showAlertErrorChgPwd &&
        <Alert bsStyle="danger" onDismiss={this.handleDismissErrorChgPwd}>
          <strong>{this.state.alertMessage}</strong>
        </Alert>
      }
      <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              type="button"
              isLoading={this.state.isLoading}
              text={ "Cambiar clave" }
              loadingText="Guardando…"
              onClick={this.changePassword}
            />
    </Form>
	  );
  }

  renderFormEdit(){
    return(
      <Form horizontal onSubmit={this.handleSubmit}>
        <FormGroup controlId="email">
          <Col componentClass={ControlLabel} sm={3}>
            Email (*)
          </Col>
          <Col sm={8}>
            <FormControl
	          onChange={this.handleChange}
	          value={this.state.email}
	          placeholder="usuario@dominio.com"
            readOnly
	        />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Email del usuario.</Tooltip>}>
              <a tabIndex="-1"><Glyphicon glyph="info-sign"/></a>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="name">
          <Col componentClass={ControlLabel} sm={3}>
            Nombre (*)
          </Col>
          <Col sm={8}>
            <FormControl
              onChange={this.handleChange}
              value={this.state.name}
              placeholder="Ingrese solo texto"
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Nombre del usuario.</Tooltip>}>
              <a tabIndex="-1"><Glyphicon glyph="info-sign"/></a>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="lastName">
	      <Col componentClass={ControlLabel} sm={3}>
	        Apellido (*)
	      </Col>
	      <Col sm={8}>
	        <FormControl
	          onChange={this.handleChange}
	          value={this.state.lastName}
	          placeholder="Ingrese solo texto"
	        />
	  	  </Col>
        <Col sm={1}>
          <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Apellido del usuario.</Tooltip>}>
            <a tabIndex="-1"><Glyphicon glyph="info-sign"/></a>
          </OverlayTrigger>
        </Col>
	    </FormGroup>
        <FormGroup controlId="phoneNumber">
	      <Col componentClass={ControlLabel} sm={3}>
	        Teléfono
	      </Col>
	      <Col sm={8}>
	        <FormControl
	          onChange={this.handleChange}
	          value={this.state.phoneNumber}
	          placeholder="Ingrese teléfono válido"
	        />
	  	  </Col>
        <Col sm={1}>
          <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Número de teléfono.</Tooltip>}>
            <a tabIndex="-1"><Glyphicon glyph="info-sign"/></a>
          </OverlayTrigger>
        </Col>
	    </FormGroup>
      {
        this.state.showAlertEditFormat &&
        <Alert bsStyle="danger" onDismiss={this.handleDismissEditFormat}>
          <strong>{this.state.alertMessageEdit}</strong>
        </Alert>
      }
      <LoaderButton
          block
          bsStyle="primary"
          bsSize="large"
          type="button"
          isLoading={this.state.isLoading}
          text={ "Modificar" }
          loadingText="Guardando…"
          onClick={this.edit} />
      </Form>
    );
  }

  render() {
    const { steps, runJoy } = this.state;
    return (
      <div className="Profile">
      	<Breadcrumb>
      	  <Breadcrumb.Item componentClass={Link} href="/" to="/">Tablero</Breadcrumb.Item>
          <Breadcrumb.Item active>Perfil</Breadcrumb.Item>
          <Glyphicon className="help" glyph="info-sign" onClick={this.handleHelp} />
      	</Breadcrumb>
    	<PageHeader>
	      Perfil <small>Datos personales</small>
	    </PageHeader>
	    {this.state.isLoadingProfile && <div><Glyphicon glyph="refresh" className="spinning" />Cargando datos... por favor espere</div> }
	    {!this.state.isLoadingProfile &&
	    <Form horizontal onSubmit={this.handleSubmit}>
	        <FormGroup controlId="email">
		      <Col componentClass={ControlLabel} sm={6}>
		        Email{' '}
		      </Col>
		      <Col sm={6}>
		        <FormControl.Static>{this.state.email}</FormControl.Static>
		      </Col>
		    </FormGroup>
		    <FormGroup controlId="name">
		      <Col componentClass={ControlLabel} sm={6}>
		        Nombre{' '}
		      </Col>
		      <Col sm={6}>
		        <FormControl.Static>{this.state.name}</FormControl.Static>
		      </Col>
		    </FormGroup>
		    <FormGroup controlId="lastName">
		      <Col componentClass={ControlLabel} sm={6}>
		        Apellido{' '}
		      </Col>
		      <Col sm={6}>
		      	<FormControl.Static>{this.state.lastName}</FormControl.Static>
		  	  </Col>
		    </FormGroup>
		    <FormGroup controlId="phoneNumber">
		      <Col componentClass={ControlLabel} sm={6}>
		        Teléfono{' '}
		      </Col>
		      <Col sm={6}>
		      	<FormControl.Static>{this.state.phoneNumber}</FormControl.Static>
		  	  </Col>
		    </FormGroup>
        <FormGroup controlId="profile">
		      <Col componentClass={ControlLabel} sm={6}>
		        Perfil{' '}
		      </Col>
		      <Col sm={6}>
		      	<FormControl.Static>{this.state.profile}</FormControl.Static>
		  	  </Col>
		    </FormGroup>
		    <FormGroup controlId="status">
		      <Col componentClass={ControlLabel} sm={6}>
		        Estado{' '}
		      </Col>
		      <Col sm={6}>
		      	<FormControl.Static>{this.state.status}</FormControl.Static>
		      </Col>
		    </FormGroup>
        {
        	this.state.showAlertSuccess &&
        	<Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        {
        	this.state.showAlertError &&
        	<Alert bsStyle="danger" onDismiss={this.handleDismissError}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
		    <FormGroup>
		      <center>
		        <Button bsStyle="primary" onClick={this.handleChangePassword} className="btnChangePass">Cambiar clave</Button>
		        <Button bsStyle="primary" onClick={this.handleEdit} className="btnUpdateProfile">Modificar datos</Button>
		      </center>
		    </FormGroup>
	    </Form>
	    }
	    <Modal show={this.state.showChangePassword} onHide={this.handleCloseChangePassword}>
        <Modal.Header closeButton>
          <Modal.Title>Cambio de clave</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderChangePasword()}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleCloseChangePassword}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
  		<Modal show={this.state.showEdit} onHide={this.handleCloseEdit}>
  		  <Modal.Header closeButton>
  		    <Modal.Title>Datos</Modal.Title>
  		  </Modal.Header>
  		  <Modal.Body>
  		    {this.renderFormEdit()}
  		  </Modal.Body>
  		  <Modal.Footer>
  		    <Button onClick={this.handleCloseEdit}>Cerrar</Button>
  		  </Modal.Footer>
  		</Modal>
      <Joyride
        steps={steps}
        run={runJoy}
        continuous={true}
        scrollToFirstStep={true}
        callback={this.callback}
        locale={{ back: 'Atrás', close: 'Cerrar', last: 'Finalizar', next: 'Siguiente', skip: 'Saltar' }} />
	  </div>
    );
  }
}

export default Profile

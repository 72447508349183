import React, { Component } from "react";
import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  Glyphicon,
  Modal,
  Tooltip,
  OverlayTrigger,
  Col,
  Alert
 } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { invokeApig } from "../../libs/awsLib";
import "./NewSupport.css";

const initialState = {
  isLoading: false,
  show: false,
  showAlertError: false,
  alertMessage: '',
  cause: '',
  subject: '',
  message: ''
};

export class NewSupport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      isEdit: props.personId ? true : false
    };
  }

  validateForm() {
    return (
      this.state.cause.length > 0 &&
      this.state.subject.length > 0 &&
      this.state.message.length > 0
    );
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleClose = () => {
    this.setState({ ...initialState, show: false });
  }

  handleShow = () => {
    this.setState({ show: true });
  }

  contactSupport(support) {
    return invokeApig({
      path: "/support",
      method: "POST",
      body: support
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    if (!this.validateForm()) {
    	this.handleAlertError('Debe ingresar todos los datos');
    	return;
    }
    this.setState({ isLoading: true });
    try {

      await this.contactSupport({
        cause: this.state.cause,
        subject: this.state.subject,
        message: this.state.message
      });
      this.props.onCreate();
      this.setState({ ...initialState, show: false });
    } catch (e) {
      this.handleAlertError('Ha ocurrido un error y no hemos podido enviar tu ticket. Favor intenta nuevamente.');
    }
  }
  handleAlertError = (message, state) => {
    this.setState({ ...state, alertMessage: message, showAlertError: true });
  }
  handleDismissError = () => {
	  this.setState({ showAlertError: false });
  }

  renderFormNew(){
    return(
      <Form horizontal onSubmit={this.handleSubmit}>
        <FormGroup controlId="cause">
	       <Col componentClass={ControlLabel} sm={2}>
  	        Causa
  	      </Col>
  	      <Col sm={9}>
  	        <FormControl componentClass="select" onChange={this.handleChange} value={this.state.cause} >
  	          <option value="">Selecciona...</option>
  	          <option value="Consulta">Consulta</option>
  	          <option value="Sugerencia">Sugerencia</option>
  	          <option value="Error">Error</option>
  	        </FormControl>
  	      </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Debe seleccionar el motivo del soporte.</Tooltip>}>
              <a tabIndex="-1"><Glyphicon glyph="info-sign"/></a>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="subject">
          <Col componentClass={ControlLabel} sm={2}>
            Asunto
  	      </Col>
  	      <Col sm={9}>
  	        <FormControl onChange={this.handleChange} value={this.state.subject} />
  	      </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Debe ingresar el motivo del soporte.</Tooltip>}>
              <a tabIndex="-1"><Glyphicon glyph="info-sign"/></a>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="message">
          <Col componentClass={ControlLabel} sm={2}>
            Mensaje
  	      </Col>
          <Col sm={9}>
            <FormControl
              componentClass="textarea"
              onChange={this.handleChange}
              value={this.state.message}
              placeholder="Escriba su mensaje..."
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Debe ingresar el mensaje del soporte.</Tooltip>}>
              <a tabIndex="-1"><Glyphicon glyph="info-sign"/></a>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        {
          this.state.showAlertError &&
          <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
            <strong>{this.state.alertMessage}</strong>
          </Alert>
        }
        <LoaderButton
          block
          bsStyle="primary"
          bsSize="large"
          type="submit"
          isLoading={this.state.isLoading}
          text="Enviar"
          loadingText="Enviando…"
          />
      </Form>
    );
  }

  render() {

    return (
      <div className="newTicket">
        <center>
      		<Button bsStyle="primary" onClick={this.handleShow} className="createTicket"><Glyphicon glyph="plus" /> Nuevo ticket</Button>
        </center>
          <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Nuevo ticket de soporte</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.renderFormNew()}
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.handleClose}>Cerrar</Button>
            </Modal.Footer>
          </Modal>
    </div>
    );
  }
}

export default NewSupport

import React, { Component } from "react";
import config from "../../config";
import { PageHeader, Jumbotron, Row, Col, Panel, Form, ControlLabel, FormControl, FormGroup, Button, Alert, Glyphicon } from "react-bootstrap";
import './Contact.css';
import {validateEmail, validateText} from '../../libs/validation';

export default class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      showAlert: false,
      alertType: '',
      alertMessage: '',
      name: '',
      email: '',
      message: ''
    };
  }
  getErrors() {
    let message = undefined;
    if (!validateText(this.state.name)) {
      message = 'Debe ingresar el nombre correctamente';
    } else {
      if (!validateEmail(this.state.email)) {
        message = 'Debe ingresar el email correctamente';
      } else {
        if (!this.state.message || this.state.message.length < 3) {
          message = 'Debe ingresar el mensaje';
        }
      }
    }
    return message;
  }
  async contact() {
    const contact = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message
    };
    const result = await fetch(config.apiGateway.URL + '/contact', {
      method: 'POST',
      headers: { 'content-type': 'application/json'},
      body: JSON.stringify(contact)
    });
    if (result.status !== 200) {
      throw new Error(await result.text());
    }
    return result.json();
  }
  handleSendContact = async (e) => {
    e.preventDefault();
    const errorMsg = this.getErrors();
    if (errorMsg) {
      this.setState({showAlert: true, alertType: 'danger', alertMessage: errorMsg});
      return;
    }
    this.setState({ isLoading:true, showAlert: false });
    this.contact().then((response) => {
      if (response.code === 0) {
        this.setState({ isLoading: false, showAlert: true, alertType: 'success', alertMessage: 'Gracias por comunicarte con nosotros. Te responderemos a la brevedad!', name: '', email: '', message: '' });
      } else {
        this.setState({ isLoading: false, showAlert: true, alertType: 'danger', alertMessage: (response.message ? response.message : 'Ha ocurrido un error al enviar el mensaje. Por favor intenta nuevamente o utiliza otro canal disponible a la izquierda de este mensaje.' ) });
      }
    }, (error) => {
      this.setState({ isLoading: false, showAlert: true, alertType: 'danger', alertMessage: 'Ha ocurrido un error al enviar el mensaje. Por favor intenta nuevamente o utiliza otro canal disponible a la izquierda de este mensaje.' });
    });
  }
  handleDismiss = () => {
    this.setState({ showAlert: false });
  }
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }
  render() {
    return (
      <div className="PublicContact">
        <PageHeader>
          ¿Necesitas más información, un demo o contratar nuestros servicio?
  	    </PageHeader>
        <Jumbotron className="contact-info">
          <Row>
            <Col md={4} sm={4}>
              <Panel bsStyle="primary">
                <Panel.Heading>
                  <Panel.Title componentClass="h3">Envíanos un e-mail</Panel.Title>
                </Panel.Heading>
                <Panel.Body>contacto@neokode.cl</Panel.Body>
              </Panel>
              <Panel bsStyle="primary">
                <Panel.Heading>
                  <Panel.Title componentClass="h3">Llámanos</Panel.Title>
                </Panel.Heading>
                <Panel.Body>+56 2 3287 5654</Panel.Body>
              </Panel>
            </Col>
            <Col md={8} sm={8}>
              <Form horizontal>

                <FormGroup controlId="name">
                  <Col componentClass={ControlLabel} sm={2}>
                    Nombre
                  </Col>
                  <Col sm={10}>
                    <FormControl type="text" placeholder="Nombre" onChange={this.handleChange} value={this.state.name} />
                  </Col>
                </FormGroup>

                <FormGroup controlId="email">
                  <Col componentClass={ControlLabel} sm={2}>
                    Email
                  </Col>
                  <Col sm={10}>
                    <FormControl type="email" placeholder="Email" onChange={this.handleChange} value={this.state.email} />
                  </Col>
                </FormGroup>

                <FormGroup controlId="message">
                  <Col componentClass={ControlLabel} sm={2}>
                    Mensaje
                  </Col>
                  <Col sm={10}>
                    <FormControl componentClass="textarea" placeholder="Mensaje" onChange={this.handleChange} value={this.state.message} />
                  </Col>
                </FormGroup>

                {
                  this.state.showAlert &&
                  <Alert bsStyle={this.state.alertType} onDismiss={this.handleDismiss}>
                    <strong>{this.state.alertMessage}</strong>
                  </Alert>
                }

                <FormGroup>
                  <Col smOffset={2} sm={10}>
                    {
                      this.state.isLoading ?
                      <Glyphicon glyph="refresh" className="spinning" />
                      :
                      <Button onClick={this.handleSendContact} className="btn btn-success btn-lg">Enviar mensaje</Button>
                    }
                  </Col>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </Jumbotron>
      </div>
    );
  }

}

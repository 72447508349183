import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Glyphicon, Alert, Row, Col } from "react-bootstrap";
import Dashboard from '../map/Dashboard';
import DashboardLegend from './DashboardLegend';
import LastAlerts from './LastAlerts';
import Joyride from 'react-joyride';
import config from '../../config';
import { getUserAttributes } from "../../libs/awsLib";

export default class DashboardAnimation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      runJoy: false,
      errorMessage: '',
      steps: [
        {
          target: 'canvas',
          content: 'En este espacio verás el estado de los sensores en tiempo real',
          placement: 'bottom',
        },
        {
          target: '.lastAlerts',
          content: 'Acá podrás ver las últimas alertas generadas',
          placement: 'bottom',
        }
      ]
    };
  }

  handleDismiss = () => {
    this.setState({ errorMessage: null });
  }
  handleHelp = () => {
    this.setState({runJoy: true});
  }
  render() {
    const { steps, runJoy } = this.state;
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item componentClass={Link} href="/" to="/">Tablero</Breadcrumb.Item>
          <Breadcrumb.Item active>Alertas</Breadcrumb.Item>
          <Glyphicon className="help" glyph="info-sign" onClick={this.handleHelp} />
        </Breadcrumb>
        <Row>
          <Col sm={8} md={8}>
            <Dashboard />
          </Col>
          <Col sm={4} md={4}>
            <DashboardLegend />
            <LastAlerts />
          </Col>
        </Row>
        {
          this.state.errorMessage &&
          <Alert bsStyle="danger" onDismiss={this.handleDismiss}>
            { this.state.errorMessage }
          </Alert>
        }
        <Joyride
          steps={steps}
          run={runJoy}
          continuous={true}
          locale={{ back: 'Atrás', close: 'Cerrar', last: 'Finalizar', next: 'Siguiente', skip: 'Saltar' }}/>
      </div>
    );
  }
}

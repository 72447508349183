import React, { Component } from "react";
import "./Report.css";
import { invokeApig } from '../../libs/awsLib';
import { Link } from "react-router-dom";
import { Breadcrumb, Glyphicon, Form, FormGroup, ControlLabel, Button, Alert } from "react-bootstrap";
import Select from 'react-select';
import AppDatePicker from '../../components/AppDatePicker';
import Joyride from 'react-joyride';
import moment from 'moment';
import ReportAlerts from '../../components/report/Alerts'
import ReportEvents from '../../components/report/Events'

export default class Report extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isMoreData: false,
      isLoadingStations: false,
      errorMessage: null,
      sensors: ['sensor1', 'sensor2', 'sensor3'],
      selectedReportType: '',
      selectedStations: null,
      optionsStations: [],
      selectedSensors: null,
      optionsSensors: [],
      dates: null,
      data: [],
      runJoy: false,
      report: [],
      lastId: null,
      optionReports:[{value: 'alerts', label: 'Reporte de alertas'}, {value: 'events', label: 'Reporte de eventos'}],
      steps: [
        {
          target: '.Report .form-inline .form-group:nth-child(1) .Select',
          content: 'Primero debes elegir el tipo de reporte.',
          placement: 'bottom',
        },
        {
          target: '.Report .form-inline .form-group:nth-child(2) .Select',
          content: 'Acá puedes elegir la estación.',
          placement: 'bottom',
        },
        {
          target: '.Report .form-inline .form-group:nth-child(3) .Select',
          content: 'Aca puedes elegir uno o más sensores.',
          placement: 'bottom',
        },
        {
          target: '.Report .form-inline .form-group:nth-child(4) .AppDatePicker',
          content: 'Acá debes seleccionar el rango de fecha. Tienes la opción de elegir una fecha absoluta o relativa a la fecha/hora actual.',
          placement: 'bottom',
        },
        {
          target: '.Report .form-inline button.btn-primary',
          content: 'Finalmente, para buscar debes presionar este botón.',
          placement: 'bottom',
        }
      ]
    };
  }

  componentDidMount() {
    this.updateStations();
  }

  updateStations = async () => {
    this.setState({ isLoadingStations: true });
    this.stations().then((response) => {
      var stations = [{value: null, label: 'Todas'}];
      response.stations.map((station, index) => {
        var sensors = [];
        for (var [key, value] of Object.entries(station.sensors)) {
          sensors.push({ value: key, label: value.name });
        }
        stations.push({ value: station.station_code, label: station.station_name, sensors: sensors });
        return null;
      });
      this.setState({ optionsStations: stations, isLoadingStations: false });
    }, (error) => {
      this.setState({ errorMessage: "Error al actualizar las estaciones. Favor intenta más tarde.", isLoadingStations: false});
    });
  }
  stations() {
      return invokeApig({
          path: "/station/find",
          method: "POST",
          body: {}
      });
  }

  handleChangeReportType = (selectedReportType) => {
    this.setState({ selectedReportType });
  }

  handleChangeStation = (selectedStations) => {
    this.setState({ selectedStations, selectedSensors: null, optionsSensors: selectedStations.sensors });
  }

  handleChangeSensor = (selectedSensors) => {
    if (selectedSensors.length > 5) {
      this.setState({ errorMessage: 'Sólo puedes elegir 5 sensores' });
    } else {
      this.setState({ selectedSensors });
    }
  }

  handleSelectDates = (dates) => {
    this.setState({ dates });
  }

  loadMoreData = async (request)=>{
    const {lastId, data, isMoreData} = this.state;
    if (!isMoreData){
      this.setState({isMoreData:true});
    }
    request.lastId= lastId;
    let newData = data.slice();
    await this.get_data(request).then((response) => {
      newData.push(...response.response);
      this.setState({ data: newData, lastId:response.lastId});
      if (response.lastId != null){
        this.loadMoreData(request);
      }else{
        this.setState({isMoreData:false});
      }
    }, (error) => {
      console.log(error);
      this.setState({ isMoreData: false, errorMessage: 'Error al obtener el reporte. Intenta nuevamente.' });
    });
  }

  handleClick = async () => {
    const { selectedStations, selectedSensors, dates } = this.state;
    if (!selectedStations || !dates) {
      this.setState({errorMessage: 'Debes seleccionar los filtros obligatorios (*)'});
      return;
    }
    this.setState({errorMessage: null, isLoading: true, data: []});
    var sensors = [];
    if (selectedSensors) {
      selectedSensors.map((sensor, index) => {
        sensors.push(sensor.value);
        return sensor;
      });
    }
    const request = {
      station: selectedStations.value,
      sensors: sensors,
      dates: {
        startDate: dates.startDate.utc().format(),
        endDate: dates.endDate.utc().format(),
        timezone: dates.timezone
      }
    };
    console.log('Request: ' + JSON.stringify(request));
    await this.get_data(request).then((response) => {
      this.setState({isLoading: false, data: response.response, lastId:response.lastId});
      if (response.lastId != null){
        this.loadMoreData(request);
      }
    }, (error) => {
      console.log(error);
      this.setState({ isLoading: false, errorMessage: 'Error al obtener el reporte. Intenta nuevamente.' });
    });
  }

  get_data(request) {
      const {selectedReportType} = this.state;
      const typeReport = selectedReportType.value;
      return invokeApig({
          path: `/report/${typeReport}`,
          method: "POST",
          body: request
      });
  }

  handleHelp = () => {
    this.setState({runJoy: true});
  }

  handleDismiss = () => {
    this.setState({errorMessage: null});
  }

  renderFilters() {
    const { selectedReportType, selectedStations, selectedSensors, optionReports } = this.state;
    return (
      <Form inline>
        <FormGroup controlId="reportType">
          <ControlLabel>Reporte (*)</ControlLabel>{' '}
          <Select value={selectedReportType} onChange={this.handleChangeReportType} options={optionReports} className="Select" placeholder="Selecciona..."/>
        </FormGroup>{' '}
        <FormGroup controlId="station">
          <ControlLabel>Estaciones (*)</ControlLabel>{' '}
          <Select value={selectedStations} onChange={this.handleChangeStation} options={this.state.optionsStations} className="Select" placeholder="Selecciona..." isLoading={this.state.isLoadingStations} loadingMessage={()=>{return "Cargando...";}} noOptionsMessage={() => {return "No hay estaciones";}}/>
        </FormGroup>{' '}
        <FormGroup controlId="sensor">
          <ControlLabel>Sensores</ControlLabel>{' '}
          <Select value={selectedSensors} onChange={this.handleChangeSensor} options={this.state.optionsSensors} isMulti={true} className="Select" placeholder="Selecciona..." noOptionsMessage={() => {return "No hay sensores";}}/>
        </FormGroup>{' '}
        <FormGroup controlId="dates">
          <ControlLabel>Rango de fecha (*)</ControlLabel>{' '}
          <AppDatePicker onSelect={this.handleSelectDates} className="AnalysisDate" timezone={this.props.timezone}/>
        </FormGroup>{' '}
        <Button type="button" onClick={this.handleClick} bsStyle="primary"><Glyphicon glyph="search" />&nbsp;&nbsp;Buscar</Button>
        {
          this.state.errorMessage &&
          <Alert bsStyle="danger" onDismiss={this.handleDismiss}>
            { this.state.errorMessage }
          </Alert>
        }
      </Form>
    );
  }

  setTableOption() {
    if (this.state.isLoading ) {
      return <div><Glyphicon glyph="refresh" className="spinning" />Cargando reporte... por favor espera</div>;
    } else {
      if (this.state.isMoreData){
        return <div><Glyphicon glyph="refresh" className="spinning" />Cargando más datos... por favor espera</div>;
      }else{
        return "No hay datos";
      }
    }
  }

  creationDateFormatter = (cell, row) => {
    const date = moment(cell); //.utcOffset(row.creationDateZone);
    return date.format("YYYY-MM-DD HH:mm:ss");
  }

  getStatusFormatter = (cell, row) => {
     if (cell === 'YELLOW'){
        return "Advertencia"
     }
     if (cell === 'RED'){
      return "Peligro"
     }
     return "Sin Estado"
  }

  renderReport() {
    const selectRowProp = {
      mode: 'radio',
      bgColor: 'pink',
      hideSelectColumn: true,
      clickToSelect: true
    };
    const tableOptions = {
        noDataText: this.setTableOption()
    };
    const { data, selectedReportType } = this.state;
    const report = selectedReportType.value;
    if (report === 'alerts'){
      return (
        <ReportAlerts data={data} tableOptions={tableOptions} selectRowProp={selectRowProp} creationDateFormatter={this.creationDateFormatter} getStatusFormatter={this.getStatusFormatter} />
      );
    }
    if (report === 'events'){
      return (
        <ReportEvents data={data} tableOptions={tableOptions} selectRowProp={selectRowProp} creationDateFormatter={this.creationDateFormatter} />
      );
    }
  }

  render() {
    const { steps, runJoy } = this.state;
    return (
      <div className="Report">
        <Breadcrumb>
          <Breadcrumb.Item componentClass={Link} href="/" to="/">Tablero</Breadcrumb.Item>
	        <Breadcrumb.Item active>Reportes</Breadcrumb.Item>
          <Glyphicon className="help" glyph="info-sign" onClick={this.handleHelp} />
        </Breadcrumb>
        { this.renderFilters() }
        { this.state.isLoading && <div><Glyphicon glyph="refresh" className="spinning" />Cargando datos... por favor espere</div> }
        { this.state.isMoreData && <div><Glyphicon glyph="refresh" className="spinning" />Cargando más datos...</div> }
        {
          !this.state.isLoading && this.state.data.length > 0 && this.renderReport()
        }
        <Joyride
          steps={steps}
          run={runJoy}
          continuous={true}
          locale={{ back: 'Atrás', close: 'Cerrar', last: 'Finalizar', next: 'Siguiente', skip: 'Saltar' }}/>
      </div>
    );
  }

}

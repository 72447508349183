import React, { Component } from "react";
import { Link } from "react-router-dom";
import { PageHeader, Breadcrumb, Glyphicon } from "react-bootstrap";
import "./Support.css";
import ListSupports from "./ListSupports";
import Joyride from 'react-joyride';

export default class Support extends Component {
  constructor(props) {
    super(props);

    this.state = {
      runJoy: false,
      steps: [
    	  {
          target: '.createTicket ',
          content: 'Acá puedes abrir el formulario para crear un nuevo ticket de soporte.',
          placement: 'top'
        },
        {
          target: '.btnQuantity',
          content: 'Para filtrar solamente por el estado de tus tickets presiona el que desees.',
          placement: 'top'
        },
        {
          target: '.react-bs-table-container',
          content: 'Puedes filtrar los tickets de soporte.',
          placement: 'top'
        },
        {
          target: '.supportLink',
          content: 'Para ver el historial del ticket puedes seleccionar este enlace.',
          placement: 'top'
        },
        {
          target: '.loadMore',
          content: 'Si haces click aquí se cargarán mas tickets.',
          placement: 'top'
        },
        {
          target: '.btnRefresh',
          content: 'Actualiza la lista de tus tickets en cualquier momento.',
          placement: 'bottom'
        }
      ]
    };
  }

  handleHelp = () => {
    this.setState({ runJoy: true});
  }

  render() {
    const { steps, runJoy } = this.state;
    return (
	  <div className="Support">
      <Joyride
		    steps={steps}
		    run={runJoy}
        continuous={true}
        scrollToFirstStep={true}
        callback={this.callback}
        locale={{ back: 'Atrás', close: 'Cerrar', last: 'Finalizar', next: 'Siguiente', skip: 'Saltar' }}
		  />
	    <Breadcrumb>
		  <Breadcrumb.Item componentClass={Link} href="/" to="/">Tablero</Breadcrumb.Item>
	      <Breadcrumb.Item active>Soporte</Breadcrumb.Item>
	      <Glyphicon className="help" glyph="info-sign" onClick={this.handleHelp} />
	    </Breadcrumb>
        <PageHeader>
          Soporte
        </PageHeader>
        <ListSupports withNew={true}/>
      </div>
    );
  }

}

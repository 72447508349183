import React, { Component } from "react";
import { invokeApig } from '../../libs/awsLib';
import { Link } from "react-router-dom";
import { Glyphicon, Button, Col } from "react-bootstrap";
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import moment from 'moment';
import './ListUser.css';

export default class ListUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isError: false,
            users: []
        };
    }
    componentDidMount() {
      this.updateUsers();
    }
    //componentWillReceiveProps
    componentDidUpdate(prevProps) {
      if (!prevProps.isLoading && this.props.isLoading) {
        this.updateUsers();
        if (this.props.onEndUpdate) {
          this.props.onEndUpdate();
        }
      }
    }
    updateUsers = async () => {
      this.setState({ isLoading: true, users: [] });
      try {
        const results = await this.users();
        this.setState({ users: results });
      } catch (e) {
        this.setState({isError: true});
      }
      this.setState({ isLoading: false });
    }
    users() {
        return invokeApig({
            path: "/user/find",
            method: "POST",
            body: {
                "page": 0,
                "size": 10
            }
        });
    }
    setTableOption() {
      if (this.state.isLoading) {
        return <div><Glyphicon glyph="refresh" className="spinning" />Cargando datos... por favor espere</div>;
      } else {
        return this.state.isError ? "Error al obtener los usuarios. Intenta más tarde." : "No hay datos";
      }
    }
    actionsFormatter = (cell, row) => {
      return <Link to={{ pathname:"/user/detail", state: row }}>{cell}</Link>;
    }
    dateFormatter = (cell, row) => {
      const date = moment(cell);
      return date.format("YYYY-MM-DD HH:mm (UTC)");
    }
    render(){
        const selectRowProp = {
          mode: 'radio',
          hideSelectColumn: true,  // enable hide selection column.
          clickToSelect: false,  // you should enable clickToSelect, otherwise, you can't select column.
          onSelect: this.onRowSelect
        };
        const tableOptions = {
          noDataText: this.setTableOption()
        };
        const data = ( this.state.users.results && this.state.users.results.length ? this.state.users.results : this.props.users );
        return(
          <Col sm={12} className="users">
            <BootstrapTable data={ data } selectRow={ selectRowProp } options={tableOptions}>
              <TableHeaderColumn dataField='name' filter={ { type: 'TextFilter', placeholder: 'Ingresa un nombre', delay: 10 } } dataSort={ true } dataFormat={ this.actionsFormatter }>Nombre</TableHeaderColumn>
              <TableHeaderColumn dataField='lastName' filter={ { type: 'TextFilter', placeholder: 'Ingresa un apellido', delay: 10 } } dataSort={ true } dataFormat={ this.actionsFormatter }>Apellido</TableHeaderColumn>
              <TableHeaderColumn dataField='email' filter={ { type: 'TextFilter', placeholder: 'Ingresa un email', delay: 10 } } dataSort={ true } isKey dataFormat={ this.actionsFormatter }>Email</TableHeaderColumn>
              <TableHeaderColumn dataField='phoneNumber' filter={ { type: 'TextFilter', placeholder: 'Ingresa un teléfono', delay: 10 } } dataSort={ true }>Teléfono</TableHeaderColumn>
              <TableHeaderColumn dataField='profile' filter={ { type: 'TextFilter', placeholder: 'Ingresa un perfil', delay: 10 } } dataSort={ true }>Perfil</TableHeaderColumn>
              <TableHeaderColumn dataField='status' filter={ { type: 'TextFilter', placeholder: 'Ingresa un estado', delay: 10 } } dataSort={ true }>Estado</TableHeaderColumn>
              <TableHeaderColumn dataField='createDate' dataFormat={ this.dateFormatter }>Fecha de creación</TableHeaderColumn>
              <TableHeaderColumn dataField='lastModifiedDate' dataFormat={ this.dateFormatter }>Última modificación</TableHeaderColumn>
            </BootstrapTable>
            {
            	this.state.lastId && (this.state.isMoreLoading ? <div><Glyphicon glyph="refresh" className="spinning" />Cargando más usuarios...</div> : <div><Button onClick={this.handleMore} className="loadMore">Cargar más...</Button></div>)
            }
          </Col>
        );
    }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Jumbotron, Glyphicon, Row, Col, Grid, Panel, Modal, Image } from "react-bootstrap";
import Joyride from 'react-joyride';
import LastAlerts from './telemetry/LastAlerts';
import { getUserAttributes } from "../libs/awsLib";
import './Home.css';

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      runJoy: false,
      email: null,
      name: null,
      lastname: null,
      profile: null,
      showVideo: false,
      steps: [
        {
          target: '.navbar-brand',
          content: 'Haciendo click acá puedes ir al inicio en todo momento',
          placement: 'bottom',
        },
        {
          target: '.navbar-nav li',
          content: 'Puedes utilizar el menú disponible para ir a las funcionalidades',
          placement: 'bottom',
        },
        {
          target: '.navbar-right li:nth-child(1)',
          content: 'Si tienes algún problema o tienes una duda puedes crear un ticket a Soporte acá.',
          placement: 'bottom',
        },
        {
          target: '.navbar-right li:nth-child(2)',
          content: 'Para crear usuarios, modificar tu perfil o cerrar sesión este es el lugar.',
          placement: 'bottom',
        }
      ]
    };
  }

  componentDidMount = async () => {
    const user = await getUserAttributes();
    this.setState({ email: user.email, name: user.name, lastName: user.family_name, profile: user.profile });
  }

  handleClose = () => {
    this.setState({ showVideoChecklist: false, showVideoForm: false, showVideoTalk: false });
  }
  handleVideo = (e) => {
    e.preventDefault();
    this.setState({ showVideo: true });
  }
  handleCloseVideo = () => {
    this.setState({ showVideo: false });
  }

  renderLander() {
    return (
      <div className="lander">
        <Jumbotron className="home-contact">
          <Image src="/logo.png"/>
          <h2>Sentinel App, telemetr&iacute;a a tu alcance.</h2>
          <p>Con <b>Sentinel Apps</b> podr&aacute;s seguir en l&iacute;nea tus indicadores, generar alertas y obtener reportes.</p>
          <h3>&iquest;Quieres conocer m&aacute;s del servicio?</h3>
          <p>
            <Link to="/contact" className="btn btn-success btn-lg">
              Contáctanos!
            </Link>
          </p>
        </Jumbotron>

        <Jumbotron className="home-sentinel">
          <h2>&iquest;Qu&eacute; puedes hacer con la telemetr&iacute;a?</h2>
          <Row>
            <Col md={6} sm={6}>
              <ul>
                <li><b>Monitoreo en l&iacute;nea: </b>Permite visualizar en tiempo real los indicadores ingresados.</li>
                <li><b>Alertas en l&iacute;nea: </b>Te informamos los indicadores que salen de rango para que puedas gestionar a tiempo.</li>
                <li><b>An&aacute;lisis: </b>Si necesitas observar un comportamiento puedes graficar los indicadores y ver su tendencia.</li>
                <li><b>Reportes web: </b> En cualquier momento puedes ver reportes hist&oacute;ricos de tus indicadores.</li>
                <li><b>Reportes por correo: </b> Te enviamos reportes mensuales de tus indicadores.</li>
                <li><b>Web y web mobile</b> Puedes utilizar la aplicaci&oacute;n desde cualquier lugar en el mundo desde diferentes dispositivos inclu&iacute;dos smartphones.</li>
              </ul>
            </Col>
            <Col md={6} sm={6}>
              <Image src="images/video.gif" onClick={this.handleVideo}/>
            </Col>
          </Row>
        </Jumbotron>
        <Modal show={this.state.showVideo} onHide={this.handleCloseVideo}>
          <Modal.Header closeButton>
            <Modal.Title>Video promocional</Modal.Title>
          </Modal.Header>
          <Modal.Body className="video-container">
            <iframe title="Sentinel" width="560" height="315" src="https://www.youtube.com/embed/HWJYOAZpnPw" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  handleHelp = () => {
    this.setState({runJoy: true});
  }
  renderUserHome() {
    const { steps, runJoy } = this.state;
    return (
  	  <div>
        <Breadcrumb>
          <Breadcrumb.Item active>
            Bienvenido {
              this.state.name ?
              <b>{this.state.name + ' ' + (this.state.lastName ? this.state.lastName : '') + ' (' + this.state.email + ')'}</b>
              :
              ''
            }
          </Breadcrumb.Item>
          <Glyphicon className="help" glyph="info-sign" onClick={this.handleHelp} />
        </Breadcrumb>
        <Grid>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <LastAlerts />
            </Col>
          </Row>
        </Grid>
        <Joyride
	        steps={steps}
	        run={runJoy}
        	continuous={true}
        	locale={{ back: 'Atrás', close: 'Cerrar', last: 'Finalizar', next: 'Siguiente', skip: 'Saltar' }}/>
      </div>
  	);
  }
  render() {
    return (
      <div className="Home">
        <Grid>
        {
        	this.props.isAuthenticated
        	?
        	this.renderUserHome()
        	:
        	this.renderLander()
        }
        </Grid>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { invokeApig } from '../../libs/awsLib';
import { Glyphicon, ButtonGroup, Button, Col, Badge, Alert } from "react-bootstrap";
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import NewSupport from "./NewSupport";
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import moment from 'moment';
import './ListSupports.css';

export default class ListSupports extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            showAlertError: false,
            alertMessage: '',
            isMoreLoading: false,
            tickets: []
        };
    }
    componentDidMount() {
    	this.updateTable();
    }
    updateTable = async () => {
    	this.setState({ isLoading: true, tickets: [], lastId: null });
      this.tickets().then((response) => {
        this.setState({ ...response, isLoading: false });
      }, (error) => {
        this.handleAlertError("Error al actualizar los tickets. Favor intenta más tarde.", { isLoading: false });
      });
    }
    tickets(lastId) {
        return invokeApig({
            path: "/support/find",
            method: "POST",
            body: lastId
        });
    }

    setTableOption() {
    	if (this.state.isLoading) {
    	  return <div><Glyphicon glyph="refresh" className="spinning" />Cargando datos... por favor espere</div>;
	    } else {
	      return "No hay datos";
	    }
    }

    supportFormatter = (cell, row) => {
        return <Link to={{ pathname:"/support/detail", state: row }}>{cell}</Link>;
    }

    handleClickResolved = () => {
        this.refs.statusCol.applyFilter('Resuelto');
    }

    handleClickInProgress = () => {
        this.refs.statusCol.applyFilter('En Progreso');
    }

    handleClickUnassigned = () => {
        this.refs.statusCol.applyFilter('Sin Asignar');
    }

    handleClickAll = () => {
        this.refs.statusCol.applyFilter('');
    }

    handleAlertError = (message, state) => {
      this.setState({ ...state, alertMessage: message, showAlertError: true });
    }

    handleDismissError = () => {
  	  this.setState({ showAlertError: false });
    }

    handleMore = async () => {
    	this.setState({ isMoreLoading: true });
    	try {
      	let results = await this.tickets({ lastId: this.state.lastId });
      	var tickets = this.state.tickets.slice();
      	results.tickets = tickets.concat(results.tickets);
        this.setState({ ...results, isMoreLoading: false });
      } catch (e) {
      	this.handleAlertError("Ocurrió un error al buscar más tickets. Favor intenta más tarde.", { isMoreLoading: false });
      }
    }
    creationDateFormatter = (cell, row) => {
      const date = moment(cell).utcOffset(row.creationDateZone);
      return date.format("YYYY-MM-DD HH:mm");
    }

    render(){
        const selectRowProp = {
          mode: 'radio',
          bgColor: 'pink', // you should give a bgcolor, otherwise, you can't regonize which row has been selected
          hideSelectColumn: true,  // enable hide selection column.
          clickToSelect: true,  // you should enable clickToSelect, otherwise, you can't select column.
          onSelect: this.onRowSelect,
        };
        const tableOptions = {
            noDataText: this.setTableOption()
        };
        const data = this.state.tickets;
        return(
          <div>
            <div>
                <Col sm={12} className="btnQuantity">
                    <Col sm={3}>
                    <Button block onClick={ this.handleClickResolved }>
                        <strong>Resueltos </strong><Badge> {this.state.solved}</Badge>
                    </Button>
                    </Col>
                    <Col sm={3}>
                    <Button block onClick={ this.handleClickInProgress }>
                        <strong>En progreso </strong><Badge> {this.state.inProgress}</Badge>
                    </Button>
                    </Col>
                    <Col sm={3}>
                    <Button block onClick={ this.handleClickUnassigned }>
                        <strong>Sin asignar </strong><Badge> {this.state.unassigned}</Badge>
                    </Button>
                    </Col>
                    <Col sm={3}>
                    <Button block onClick={ this.handleClickAll }>
                        <strong>Total tickets </strong><Badge> {this.state.total}</Badge>
                    </Button>
                    </Col>
                </Col>
            </div>
            <ButtonGroup className="update">
              {
                this.props.withNew &&
                <NewSupport onCreate={this.handleCreate}/>
              }
            	<Button onClick={this.updateTable} className="btnRefresh">
  	      			<Glyphicon glyph="refresh" />
  	      		</Button>
            </ButtonGroup>
            <Col sm={12} className="tickets">
              {
                this.state.showAlertError &&
                <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
                  <strong>{this.state.alertMessage}</strong>
                </Alert>
              }
            	<BootstrapTable data={ data } selectRow={ selectRowProp } options={tableOptions}>
                  <TableHeaderColumn dataField='id' filter={ { type: 'TextFilter', placeholder: 'Ingresa un ticket', delay: 10 } } dataAlign='right' dataFormat={ this.supportFormatter } isKey columnClassName='supportLink'>Número ticket</TableHeaderColumn>
                  <TableHeaderColumn dataField='subject' filter={ { type: 'TextFilter', placeholder: 'Ingresa un asunto', delay: 10 } }  dataSort={ true }>Asunto</TableHeaderColumn>
                  <TableHeaderColumn dataField='cause' filter={ { type: 'TextFilter', placeholder: 'Ingresa una causa', delay: 10 } }  dataSort={ true }>Causa</TableHeaderColumn>
                  <TableHeaderColumn dataField='username' filter={ { type: 'TextFilter', placeholder: 'Ingresa un usuario', delay: 10 } } dataSort={ true }>Usuario</TableHeaderColumn>
                  <TableHeaderColumn ref='statusCol' dataField='status' filter={ { type: 'TextFilter', placeholder: 'Ingresa un estado', delay: 10 } } dataSort={ true }>Estado</TableHeaderColumn>
                  <TableHeaderColumn dataField='creationDate' filter={ { type: 'TextFilter', placeholder: 'Ingresa una fecha', delay: 10 } } dataSort={ true } dataFormat={ this.creationDateFormatter }>Fecha de creación</TableHeaderColumn>
              </BootstrapTable>
              {
              	this.state.lastId && (this.state.isMoreLoading ? <div><Glyphicon glyph="refresh" className="spinning" />Cargando más tickets...</div> : <div><Button onClick={this.handleMore} className="loadMore">Cargar más...</Button></div>)
              }
            </Col>
          </div>
        );
    }
}
